import { ISimpleOrder, IPagedData } from '../../types';
import Fetch from '../apiConfig/apiConfig';
import queryString from 'query-string';

interface IGetOrdersPageParams {
  dateFilter: string;
  searchByDeliverAt: boolean;
  storeId: number;
  currentPage: number;
  pageSize: number;
  pending: boolean;
}

interface IOrdersResponse {
  response: IPagedData<ISimpleOrder>;
}

export const getOrdersPage = async (params: IGetOrdersPageParams): Promise<IPagedData<ISimpleOrder>> => {
  const axios = await Fetch();
  const { data } = await axios.get<IOrdersResponse>(
    `/orders/confirmed/page?${queryString.stringify(params)}`,
  );

  return data.response;
};
