import { IPersistedOrderFilters } from '../../../types';

export const persistOrderFiltersAction = ({
  selectedDate,
  statusFilter,
  dateFilter,
  currentPage,
  selectedStore,
}: IPersistedOrderFilters) => {
  return {
    type: 'ORDERS_LIST_FILTERS_PERSIST',
    payload: {
      selectedDate,
      statusFilter,
      dateFilter,
      currentPage,
      selectedStore,
    },
  };
};
