import { Reducer } from 'redux';
import { IScannedItem } from '../types';
import { SET_SCANNED_ITEMS, UPDATE_SCANNED_ITEM_QUANTITY } from '../actions/Products/scannedItemsAction';

const scannedItemsReducer: Reducer<IScannedItem[]> =
(state: IScannedItem[] = [], action : any) => {
  switch (action.type) {
    case SET_SCANNED_ITEMS: {
      return action.payload;
    }
    case UPDATE_SCANNED_ITEM_QUANTITY: {
      const { itemId, quantity } = action.payload;
      return state.map(item =>
        item.itemId === itemId
          ? { ...item, quantity }
          : item,
      );
    }
    default: {
      return state;
    }
  }
};

export default scannedItemsReducer;
