import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import { IGetRetailersResponse } from './IGetRetailersResponse';
import { IGetRetailerDetailsResponse } from './IGetRetailerDetailsResponse';
import { IUpdateRetailerMetaParams } from './IUpdateRetailerMetaParams';
import { IUpdateRetailersTenantsParams } from './IUpdateRetailersTenantsParams';

export const getRetailers = () : AxiosPromise<IGetRetailersResponse> => {
  return Fetch().then(axios => axios.get('supplychain/retailers'));
};

export const getRetailerDetails = () : AxiosPromise<IGetRetailerDetailsResponse> => {
  return Fetch().then(axios => axios.get('supplychain/retailer/detail'));
};

export const updateRetailerMeta = (params: IUpdateRetailerMetaParams): AxiosPromise => {
  return Fetch().then(axios => axios.post('supplychain/retailer/update-meta', params));
};

export const updateTenants = (params: IUpdateRetailersTenantsParams): AxiosPromise => {
  return Fetch().then(axios => axios.post('users/update-tenant', params));
};
