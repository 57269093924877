import { updateBasketDetails, updateBasketDetailsWithItems } from '../../api/Basket/UpdateBasketApi';
import { IUpdateBasketResponse } from '../../api/Basket/IUpdateBasketResponse';
import { AxiosResponse } from 'axios';
import { toastr } from 'react-redux-toastr';
import { navigateTo } from '../../routing/Navigation';
import { IScannedItem } from '../../types';
import { SET_SCANNED_ITEMS } from '../Products/scannedItemsAction';

export const updateBasketRequest = (itemId: number, quantity: number): any => {
  return (dispatch : any) => {
    dispatch({
      type: 'UPDATE_BASKET_REQUEST',
    });

    return updateBasketDetails(itemId, quantity)
      .then((response: AxiosResponse<IUpdateBasketResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: 'UPDATE_BASKET_RESPONSE',
            response: response.data.basket,
          });
        } else {
          onError(dispatch, 'An error has occured adding an item to your basket.');
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch, 'An error has occured adding an item to your basket.');
      });
  };
};

export const updateBasketWithItemsRequest = (scannedItems: IScannedItem[]): any => {
  return (dispatch : any) => {
    dispatch({
      type: 'UPDATE_BASKET_REQUEST',
    });

    return updateBasketDetailsWithItems(scannedItems)
      .then((response: AxiosResponse<IUpdateBasketResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: 'UPDATE_BASKET_RESPONSE',
            response: response.data.basket,
          });
          dispatch({
            type: SET_SCANNED_ITEMS,
            payload: [],
          });
          toastr.success('Basket Updated', 'All items have been successfully added to your basket.');
          navigateTo('/basket');
        } else {
          onError(dispatch, 'An error has occured adding an item to your basket.');
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch, 'An error has occured adding an item to your basket.');
      });
  };
};

const onError = (dispatch: any, message: string = null) => dispatch({
  type: 'UPDATE_BASKET_ERROR',
  response: message,
});
