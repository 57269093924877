import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import { IUpdateBasketResponse } from './IUpdateBasketResponse';
import { IScannedItem } from '../../types';

export const updateBasketDetails = (itemId: number, quantity: number): AxiosPromise<IUpdateBasketResponse> => {
  return Fetch().then(axios => axios.post('update-basket-item', { ItemId:itemId, Quantity:quantity }));
};

export const updateBasketDetailsWithItems = (scannedItems: IScannedItem[]): AxiosPromise<IUpdateBasketResponse> => {
  return Fetch().then(axios => axios.post('update-basket-items', { scannedItems }));
};
