import React from 'react';
import { connect } from 'react-redux';
import { IWarningCardProps } from './IWarningCardProps';
import styles from './WarningCard.module.scss';

function WarningCard({
  children,
}: Readonly<IWarningCardProps>): JSX.Element {
  return (
    <div className={styles.container}>
      {children}
    </div>
  );
}

export default connect(null, null)(WarningCard);
