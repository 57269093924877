import { combineReducers, Reducer } from 'redux';
import { IStoreState } from '../types/index';
import siteWrapper from './siteWrapperReducer';
import userDetails from './userDetailsReducer';
import { reducer as toastr } from 'react-redux-toastr';
import userLogin from './userLoginReducer';
import userLogout from './userLogoutReducer';
import categories from './categoriesReducer';
import topOffers from './topOffersReducer';
import homeTopOffers from './homeTopOffersReducer';
import products from './productReducer';
import productPage from './productPageReducer';
import subCategories from './subCategoriesReducer';
import tradingGroups from './tradingGroupsReducer';
import searchResults from './searchResultsReducer';
import catalogueMeta from './catalogueMetaReducer';
import forgotPassword from './forgotPasswordReducer';
import forgotPasswordConfirmation from './forgotPasswordConfirmationReducer';
import users from './usersReducer';
import removeUser from './removeUserReducer';
import documentsFolderContents from './Documents/getFolderContentsReducer';
import documentsUploadDocument from './Documents/uploadDocumentReducer';
import documentsDownloadBlob from './Documents/downloadDocumentBlobReducer';
import documentsEnsurePath from './Documents/ensurePathReducer';
import documentsUpdateFolder from './Documents/updateFolderReducer';
import documentsDeleteDocument from './Documents/deleteDocumentReducer';
import documentsDeleteFolder from './Documents/deleteFolderReducer';
import documentsEditDocument from './Documents/editDocumentReducer';
import documentsDocumentDetails from './Documents/getDocumentDetailsReducer';
import registration from './registerUserReducer';
import retailers from './retailersReducer';
import updateRetailerMeta from './updateRetailerMetaReducer';
import customerGroups from './customerGroupsReducer';
import signUp from './signUpReducer';
import retailerDetails from './retailerDetailsReducer';
import orderSearch from './orderSearchReducer';
import orderItems from './orderItemsReducer';
import impersonateUser from './impersonateUserReducer';
import persistedOrderListFilters from './Orders/OrdersList/persistOrderFiltersReducer';
import contactForm from './MyAccount/contactFormReducer';
import resetPassword from './MyAccount/resetPasswordReducer';
import orderConfirmation from './Orders/OrdersList/OrderConfirmationReducer';
import orderEdn from './Orders/OrdersList/OrderEdnReducer';
import basket from './Basket/BasketReducer';
import userStores from './Users/getUserStoresReducer';
import retailerOrders from './Basket/retailerOrdersReducer';
import orderImportCsvDownload from './Basket/getOrderImportCsvReducer';
import orderImportCsvUpload from './Basket/uploadOrderImportCsvReducer';
import adminContactForm from './Admin/adminContactFormReducer';
import adminRetailers from './Admin/adminRetailersReducer';
import confirmedOrders from './Reports/confirmedOrdersReducer';
import orderPoNumber from './Basket/updateOrderPoNumberReducer';
import userAcceptance from './Reports/userAcceptanceReducer';
import documentTags from './Documents/getTagsReducer';
import exclusions from './productExclusionsReducer';
import stores from './Stores/storesReducer';
import transportCosts from './Stores/transportCostsReducer';
import rebates from './Rebates/rebatesReducer';
import uploadStoreDocuments from './StoreDocuments/uploadStoreDocumentReducer';
import retailerStoreDocuments from './StoreDocuments/retailerStoreDocumentsReducer';
import storeDocumentsDownloadBlob from './StoreDocuments/downloadStoreDocumentBlobReducer';
import userStoresDocuments from './StoreDocuments/userStoresDocumentsReducer';
import orderOpportunities from './OrderOpportunities/orderOpportunitiesReducer';
import createOrder from './Basket/createOrderReducer';
import {
  presales,
  regions,
 } from './SupplyChain';
import {
  cancelOrder,
  dispatchedOrders,
  orderClaimsReview,
  orderDispatch,
  orderItemsWithChanges,
  orderLookup,
  orderReview,
  ordersDisplayMode,
  pendingOrdersOptions,
  reviewOrderClaimsDisplayMode,
  orderClaimDetails,
  stockAllocations,
  updateOrderStatus,
  scheduledDeliveries,
} from './Orders';
import orderOpportunitiesCountdown from './OrderOpportunities/orderOpportunitiesCountdownReducer';
import shoppingLists from './ShoppingLists/shoppingListsReducer';
import shoppingList from './ShoppingLists/shoppingListReducer';
import {
  tradingPack,
  tradingPacks,
  currentTradingPack,
  deleteTradingPack,
  saveTradingPack,
  retailerProductPricing,
  exportRetailerProductPricing,
  importRetailerProductPricing,
} from './Products';
import scannedItems from './scannedItemsReducer';

const appReducer: Reducer<IStoreState> = combineReducers<IStoreState>({
  siteWrapper,
  userDetails,
  toastr,
  userLogin,
  userLogout,
  categories,
  topOffers,
  homeTopOffers,
  products,
  productPage,
  subCategories,
  tradingGroups,
  searchResults,
  catalogueMeta,
  forgotPassword,
  forgotPasswordConfirmation,
  users,
  removeUser,
  documentsFolderContents,
  documentsUploadDocument,
  documentsDownloadBlob,
  documentsEnsurePath,
  documentsUpdateFolder,
  documentsDeleteDocument,
  documentsDeleteFolder,
  documentsEditDocument,
  documentsDocumentDetails,
  registration,
  retailers,
  updateRetailerMeta,
  customerGroups,
  signUp,
  retailerDetails,
  orderSearch,
  orderItems,
  impersonateUser,
  persistedOrderListFilters,
  contactForm,
  resetPassword,
  orderConfirmation,
  orderEdn,
  basket,
  userStores,
  retailerOrders,
  orderImportCsvDownload,
  orderImportCsvUpload,
  ordersDisplayMode,
  pendingOrdersOptions,
  cancelOrder,
  adminContactForm,
  adminRetailers,
  confirmedOrders,
  orderPoNumber,
  updateOrderStatus,
  userAcceptance,
  documentTags,
  exclusions,
  stores,
  transportCosts,
  rebates,
  uploadStoreDocuments,
  retailerStoreDocuments,
  storeDocumentsDownloadBlob,
  userStoresDocuments,
  stockAllocations,
  orderReview,
  orderDispatch,
  dispatchedOrders,
  orderOpportunities,
  orderLookup,
  createOrder,
  orderItemsWithChanges,
  reviewOrderClaimsDisplayMode,
  orderClaimsReview,
  orderClaimDetails,
  orderOpportunitiesCountdown,
  shoppingLists,
  shoppingList,
  presales,
  currentTradingPack,
  tradingPacks,
  tradingPack,
  deleteTradingPack,
  saveTradingPack,
  scheduledDeliveries,
  regions,
  retailerProductPricing,
  exportRetailerProductPricing,
  importRetailerProductPricing,
  scannedItems,
});

const rootReducer = (state: IStoreState, action: any) => {
  if (action.type === 'PURGE_REDUX') {
    // tslint:disable-next-line: no-parameter-reassignment
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
