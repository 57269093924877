import { Dispatch } from 'redux';
import { IScannedItem } from '../../types';

export const SET_SCANNED_ITEMS = 'SET_SCANNED_ITEMS';
export const UPDATE_SCANNED_ITEM_QUANTITY = 'UPDATE_SCANNED_ITEM_QUANTITY';

export const setScannedItems = (items: IScannedItem[]): (dispatch: Dispatch) => Promise<void> => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SET_SCANNED_ITEMS,
      payload: items,
    });
  };
};

export const updateScannedItemQuantity = (itemId: number, quantity: number): (dispatch: Dispatch) => Promise<void> => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_SCANNED_ITEM_QUANTITY,
      payload: {
        itemId,
        quantity,
      },
    });
  };
};
