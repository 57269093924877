import React from 'react';
import { Button, Card } from '@orderly/morrisons-component-library';
import { navigateTo } from '../../../../routing/Navigation';
import styles from './StepCard.module.scss';

interface IStepCardProps {
  currentStep: number;
  maxSteps: number;
  uploadingBarcodes: boolean;
  onContinue: () => void;
  onBack: () => void;
}

const StepCard: React.FC<IStepCardProps> = ({
  currentStep,
  maxSteps,
  uploadingBarcodes,
  onContinue,
  onBack,
}) => (
  <div className={styles.container}>
    <Card className={styles.card}>
      {currentStep === 1 && (
        <div>
          <h4>Step {currentStep} of {maxSteps}: Please scan to clear down your device</h4>
          <div className={`${styles.content} ${styles.column}`}>
            <span>Scan QR code to erase barcodes from your scanner</span>
            <div>
              <img
                src="https://wholesale-cdn.orderly.io/scanner/clear_data.png"
                alt="Clear Scanner Data QR Code"
              />
            </div>
          </div>
        </div>
      )}
      {currentStep === 2 && (
        <div>
          <h4>Step {currentStep} of {maxSteps}: Scanning barcodes in your store</h4>
          <div className={styles.content}>
            <div>
              <ol>
                <li>Scan the barcode on either the SEL or the product</li>
                <li>Scanning the same barcode multiple times will add multiple quantity to your order</li>
              </ol>
              <a href="#" onClick={() => navigateTo('/scanner/user-guide?section=adjust-settings')}>
                Adjust Volume / Vibrate Settings
              </a>
            </div>
            <div>
              <img
                src="https://wholesale-cdn.orderly.io/scanner/shelf_edge_label.png"
                alt="Shelf Edge Label Example"
              />
            </div>
            <div>
              <img
                className={styles.image}
                src="https://wholesale-cdn.orderly.io/scanner/product_barcode.png"
                alt="Product Barcode Example"
              />
            </div>
          </div>
        </div>
      )}
      {currentStep === 3 && (
        <div>
          <h4>Step {currentStep} of {maxSteps}: Upload barcodes into order</h4>
          <div className={`${styles.content} ${styles.column}`}>
            <div>
              <i>Your scanner must be connected to your PC via a USB cable</i><br />
              <a href="#" onClick={() => navigateTo('/scanner/user-guide?section=turn-on-scanner')}>
                How do I do this?
              </a>
            </div>

            <div className={styles.upload}>
              <img src="https://wholesale-cdn.orderly.io/scanner/data_upload.png" alt="Data Upload QR Code" />
              <div className={styles.help}>
                <i>Scan QR code to upload barcodes</i>
                {uploadingBarcodes ? 'Uploading...' : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </Card>
    <div className={styles.controls}>
      {
        currentStep !== 1 &&
        <Button
          type="button"
          className="tertiary"
          text="Back"
          onClick={onBack}
        />
      }
      {
        currentStep !== maxSteps &&
        <Button
          type="button"
          className="tertiary"
          text="Continue"
          onClick={onContinue}
        />
      }
    </div>
  </div>

);

export default StepCard;
