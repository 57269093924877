import React, { useEffect, useState } from 'react';
import { FullPageLayout } from '../../layouts';
import { useQuery } from '@tanstack/react-query';
import { IApiRequest, IBasket, IRetailerDetails, IScannedItem, IShoppingList, IStoreState } from '../../types';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useDebounce } from '../../helpers/Hooks';
import { updateBasketWithItemsRequest } from '../../actions/Basket/updateBasketAction';
import { getProductsByBarcode } from '../../api/Products/productsApi';
import { QUERY_KEYS } from '../../constants';
import { toastr } from 'react-redux-toastr';
import { submitProductBarcodesRequest } from '../../actions/ShoppingLists/shoppingListActions';
import { IAddProductsToShoppingListBody } from '../../api/ShoppingLists/shoppingListsApi';
import { setScannedItems } from '../../actions/Products/scannedItemsAction';
import { BarcodeResults, HeaderControls, StepCard } from './components';
import styles from './Scanner.module.scss';

interface IScannerProps {
  retailerDetails: IApiRequest<IRetailerDetails>;
  basket: IApiRequest<IBasket>;
  scannedItems: IScannedItem[];
  shoppingList: IApiRequest<IShoppingList>;
  addToAllBasket: (scannedItems: IScannedItem[]) => void;
  submitProductBarcodes: (
    body: IAddProductsToShoppingListBody,
    onSuccess: (shoppingList: IShoppingList[], unmatchedBarcodes: string[]) => void,
  ) => void;
  updateScannedItems: (items: IScannedItem[]) => void;
}

function Scanner({
  retailerDetails,
  basket,
  addToAllBasket,
  shoppingList,
  scannedItems,
  submitProductBarcodes,
  updateScannedItems,
}: Readonly<IScannerProps>): JSX.Element {
  const MAX_STEPS = 3;
  const [maxOrderQuantity, setMaxOrderQuantity] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [barcodes, setBarcodes] = useState<string[]>([]);
  const debouncedBarcodes = useDebounce(barcodes, 2000);
  const uploadingBarcodes = barcodes.length !== debouncedBarcodes.length;
  const showProducts = !uploadingBarcodes && debouncedBarcodes.length > 0;
  const [showPopup, setShowPopup] = useState(false);
  const [missingBarcodes, setMissingBarcodes] = useState<string[]>([]);
  const searchPhrase = debouncedBarcodes.join(',');

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.PRODUCT_BARCODE_LOOKUP, searchPhrase],
    queryFn: () => getProductsByBarcode(searchPhrase),
    enabled: !uploadingBarcodes && debouncedBarcodes.length > 0,
  });

  const products = (data && data.products) ? data.products : [];
  const hasProducts = products.length > 0;
  const productsLoaded = showProducts && !isLoading && hasProducts;

  useEffect(
    () => {
      if (retailerDetails && retailerDetails.data) {
        const maximumOrderQuantity = retailerDetails.data.meta.MaxOrderQuantity;
        if (maximumOrderQuantity) {
          setMaxOrderQuantity(parseInt(maximumOrderQuantity, 10));
        }
      }
    },
    [retailerDetails],
  );

  const onContinue = () => {
    setCurrentStep(prev => Math.min(prev + 1, MAX_STEPS));
  };

  const onBack = () => {
    setCurrentStep(prev => Math.max(prev - 1, 1));
  };

  useEffect(
    () => {
      let buffer: string = '';
      const timeout: NodeJS.Timeout | null = null;

      const handleKeydown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
          if (buffer) {
            setBarcodes(prevBarcodes => [...prevBarcodes, buffer]);
            buffer = '';
          }
          return;
        }

        if (/^\d$/.test(event.key)) {
          buffer += event.key;
        }
      };

      window.addEventListener('keydown', handleKeydown);

      return () => {
        if (timeout) clearTimeout(timeout);
        window.removeEventListener('keydown', handleKeydown);
      };
    },
    [],
  );

  useEffect(
    () => {
      if (!isLoading && data) {
        updateScannedItems(data.scannedItems);

        if (data.missingBarcodes && data.missingBarcodes.length > 0) {
          setMissingBarcodes(data.missingBarcodes);
          setShowPopup(true);
        }
      }
    },
    [data, isLoading],
  );

  const onAddAllToBasket = () => {
    addToAllBasket(scannedItems);
  };

  const onSubmitProductBarcodes = () => {
    const toastrConfirmOptions = {
      onOk: () => {
        submitProductBarcodes(
          { barcodes: searchPhrase, id: shoppingList.data.id },
          (_, unmatchedBarcodes: string[]) => {
            if (unmatchedBarcodes.length > 0) {
              toastr.warning(
                'Unmatched Barcodes',
                'Some barcodes could not be matched with products and were not added to the shopping list.',
              );
            } else {
              toastr.success(
                'Product Barcodes Submitted',
                'Products have been submitted to the shopping list successfully.',
              );
            }
          },
        );
      },
    };

    toastr.confirm(
      'Are you sure you want to submit the provided list of barcodes to the current shopping list?',
      toastrConfirmOptions,
    );
  };

  return (
    <FullPageLayout
      heading="Scanner"
      breadcrumbs={[
        {
          key: 0,
          text: 'Scanner',
          url: '/scanner',
        },
        {
          key: 0,
          text: 'Place Order',
          url: window.location.href,
        },
      ]}
      headerAdditionalContent={
        <HeaderControls
          productsLoaded={productsLoaded}
          onAddAllToBasket={onAddAllToBasket}
          onSubmitProductBarcodes={onSubmitProductBarcodes}
          basketLoading={basket.loading}
          shoppingListId={shoppingList.data.id}
        />
      }
    >
      <div className={styles.container}>
        {
          !showProducts &&
          <StepCard
            currentStep={currentStep}
            maxSteps={MAX_STEPS}
            uploadingBarcodes={uploadingBarcodes}
            onContinue={onContinue}
            onBack={onBack}
          />
        }
        {
          showProducts && (
            <BarcodeResults
              isLoading={isLoading}
              products={products}
              maxOrderQuantity={maxOrderQuantity}
              missingBarcodeTitle={`Invalid ${missingBarcodes.length === 1 ? 'barcode' : 'barcodes'}`}
              missingBarcodes={missingBarcodes}
              productsLoaded={productsLoaded}
              showPopup={showPopup}
              onClosePopup={() => setShowPopup(false)}
            />
          )
        }
      </div>
    </FullPageLayout >
  );
}

const mapStateToProps = (state: IStoreState) => {
  return {
    retailerDetails: state.retailerDetails,
    basket: state.basket,
    shoppingList: state.shoppingList,
    scannedItems: state.scannedItems,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    addToAllBasket: (scannedItems: IScannedItem[]) => dispatch(updateBasketWithItemsRequest(scannedItems)),
    submitProductBarcodes: (
      body: IAddProductsToShoppingListBody,
      onSuccess: (shoppingList: IShoppingList[], unmatchedBarcodes: string[]) => void,
    ) => dispatch(submitProductBarcodesRequest(body, onSuccess)),
    updateScannedItems: (items: IScannedItem[]) => dispatch(setScannedItems(items)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Scanner);
