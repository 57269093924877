import React, { useState, Dispatch, useEffect } from 'react';
import { IOrderDetailsProps } from './IOrderDetailsProps';
import LoadingThrobber from '../../../components/LoadingThrobber/LoadingThrobber';
import { Card, Dropdown, Button } from '@orderly/morrisons-component-library';
import { navigateTo } from '../../../routing/Navigation';
import Table, { isHeaderSorted } from '../../../components/Table/Table';
import { IStoreState, IOrderItem, IOrderDetails } from '../../../types';
import { connect } from 'react-redux';
import { formatCurrency } from '../../../helpers/Format';
import { orderItemsRequest } from '../../../actions/Orders/orderItemsAction';
import { orderSearchRequest } from '../../../actions/Orders/orderSearchAction';
import { IOrderItemSortBy } from './IOrderItemSortBy';
import OrderStatus from '../../../components/OrderStatus/OrderStatus';
import OrderError from '../../../components/OrderError/OrderError';
import { mapCategory } from '../../../helpers/Category/mapCategory';
import { IDateValues } from '../../../helpers/Format/Date/IDateValues';
import FormatDateHelper from '../../../helpers/Format/Date/FormatDateHelper';
import OrderConfirmationTemplate from '../../../components/OrderConfirmationTemplate/OrderConfirmationTemplate';
import { orderConfirmationRequest } from '../../../actions/Orders/orderConfirmationAction';
import EdnTemplate from '../../../components/EdnTemplate/EdnTemplate';
import { orderEdnRequest } from '../../../actions/Orders/orderEdnAction';
import { FullPageLayout } from '../../../layouts';
import styles from './OrderDetails.module.scss';
import OrderInfoCard from '../../../components/OrderInfoCard';

const OrderDetails = ({
  match,
  orderDetails,
  orderItems,
  getOrderItems,
  getOrderDetails,
  retailer,
  getOrderConfirmation,
  orderConfirmation,
  getOrderEdn,
  orderEdn,
}: IOrderDetailsProps) => {
  const [orderBy, setOrderBy] = useState('description-asc');
  const [orderDay, setOrderDay] = useState('');
  const [orderMonth, setOrderMonth] = useState('');
  let order: IOrderDetails = null;

  if (orderDetails.loading === false
    && !orderDetails.error
    && orderDetails.data !== null
    && orderDetails.data.orders.length === 1) {
    order = orderDetails.data.orders[0];

    if (order.orderId !== match.params.orderId) {
      order = null;
    }
  }

  if (order === null
    && !orderDetails.loading
    && !orderDetails.error) {
    getOrderDetails(match.params.orderId, retailer.meta.ApiIdentifier);
  }

  const loadOrderConfirmation = () => {
    return getOrderConfirmation(match.params.orderId, retailer.meta.ApiIdentifier);
  };

  const loadEdn = () => {
    return getOrderEdn(match.params.orderId, retailer.meta.ApiIdentifier);
  };

  useEffect(
    () => {
      if (order) {
        const dateValues: IDateValues = FormatDateHelper.getDateValues(order.orderRaisedDate);

        setOrderDay(dateValues.date);
        setOrderMonth(dateValues.month);
      }
      getOrderItems(match.params.orderId, retailer.meta.ApiIdentifier);
    },
    [order],
  );

  const sortOrderItems = (items: IOrderItem[]): IOrderItem[] => {
    return items.sort((item1: IOrderItem, item2: IOrderItem): number => orderByFunction[orderBy](item1, item2));
  };

  const sortedColumnName = orderBy.substring(0, orderBy.lastIndexOf('-'));
  const sortedDescending = orderBy.substring(orderBy.lastIndexOf('-') + 1) === 'dsc';

  const viewDetails = (id: string): void => {
    navigateTo(`/product/${id}`);
  };

  return (
    <FullPageLayout
      heading={`Order #${match.params.orderId}`}
      breadcrumbs={[
        { key: 0, text: 'My Orders', url: '/orders' },
        { key: 1, text: `Order #${match.params.orderId}`, url: window.location.pathname },
      ]}
    >
      {(orderDetails.loading || orderItems.loading ||
        !orderConfirmation.loading || !orderEdn.loading) && <LoadingThrobber />}
      {orderDetails.error && <OrderError />}
      {!orderDetails.error && (
        <div className={styles.container}>
          <div className={`${styles.column} ${styles.leftColumn}`}>
            <OrderInfoCard
              orderDay={orderDay}
              orderMonth={orderMonth}
              orderNumber={match.params.orderId}
            >
              <table className={styles.orderDetails}>
                <tbody>
                  <tr>
                    <td>PO Number</td>
                    <td>
                      {
                        order && order.customerPONumber ?
                          order.customerPONumber :
                          'N/A'
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Order date</td>
                    <td>
                      {order && FormatDateHelper.formatShortDate(order.orderRaisedDate)}
                    </td>
                  </tr>
                  <tr>
                    <td>Delivery date</td>
                    <td>{order && FormatDateHelper.formatShortDate(order.shipToDeliverAt)}</td>
                  </tr>
                  <tr>
                    <td>Items number</td>
                    <td>{order && order.itemsOrderedSumTotal}</td>
                  </tr>
                  <tr>
                    <td>Category</td>
                    <td>{order && mapCategory(order.orderReferenceCode)}</td>
                  </tr>
                  <tr>
                    <td>Invoiced value</td>
                    <td>
                      {
                        order && formatCurrency(parseFloat(order.billToTotalNetAmount) * 100)
                      }
                    </td>
                  </tr>
                  {
                    order && order.billToTotalTaxAmount &&
                    <tr>
                      <td>Invoiced tax</td>
                      <td>
                        {
                          formatCurrency(parseFloat(order.billToTotalTaxAmount) * 100)
                        }
                      </td>
                    </tr>
                  }

                  <tr>
                    <td>Status</td>
                    <td>
                      <OrderStatus status={order && order.statusCurrent} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </OrderInfoCard>
            {
              order && order.statusCurrent !== 'raised' &&
              <Card className={styles.leftColumn}>
                <Button
                  type="button"
                  className="button link print-pdf"
                  onClick={loadOrderConfirmation}
                  text="Download Order Confirmation"
                >
                  Download Order Confirmation
                </Button>
              </Card>
            }
            {
              order && order.statusCurrent === 'shipped' &&
              <Card className={styles.leftColumn}>
                <Button
                  type="button"
                  className="button link print-pdf"
                  onClick={loadEdn}
                  text="Download EDN"
                >
                  Download EDN
                </Button>
              </Card>
            }
            {
              order && order.statusCurrent === 'accounted' &&
              <Card className={styles.leftColumn}>
                <Button
                  type="button"
                  className="button link print-pdf"
                  onClick={loadEdn}
                  text="Download EDN"
                >
                  Download EDN
                </Button>
              </Card>
            }
            {
              order && order.statusCurrent === 'invoiced' &&
              <Card className={styles.leftColumn}>
                <Button
                  type="button"
                  className="button link print-pdf"
                  onClick={loadEdn}
                  text="Download EDN"
                >
                  Download EDN
                </Button>
              </Card>
            }
          </div>
          <div className={styles.column}>
            <div className={styles.orderItems}>
              <div className={styles.headerWrapper}>
                <Dropdown
                  className={styles.sort}
                  label=""
                  name="order-items-sort"
                  onChange={(e: any) => setOrderBy(e.value)}
                  options={[
                    { label: 'Delivered QT: low-high', value: 'delivered-quantity-asc' },
                    { label: 'Delivered QT: high-low', value: 'delivered-quantity-dsc' },
                    { label: 'Case Size: low-high', value: 'case-size-asc' },
                    { label: 'Case Size: high-low', value: 'case-size-dsc' },
                    { label: 'Order QT: low-high', value: 'order-quantity-asc' },
                    { label: 'Order QT: high-low', value: 'order-quantity-dsc' },
                    { label: 'Price: low-high', value: 'price-asc' },
                    { label: 'Price: high-low', value: 'price-dsc' },
                    { label: 'Product Id: low-high', value: 'id-asc' },
                    { label: 'Product Id: high-low', value: 'id-dsc' },
                    { label: 'Product Name: A-Z', value: 'description-asc' },
                    { label: 'Product Name: Z-A', value: 'description-dsc' },
                  ]}
                  selectedValue={orderBy}
                />
              </div>

              <Table className="order-items-table">
                <thead>
                  <tr>
                    <th>Product Name{isHeaderSorted('description', sortedColumnName, sortedDescending)}</th>
                    <th>Product Id{isHeaderSorted('id', sortedColumnName, sortedDescending)}</th>
                    <th>Order QT{isHeaderSorted('order-quantity', sortedColumnName, sortedDescending)}</th>
                    <th>Delivered QT{isHeaderSorted('delivered-quantity', sortedColumnName, sortedDescending)}</th>
                    <th>Case Size{isHeaderSorted('case-size', sortedColumnName, sortedDescending)}</th>
                    <th>Price{isHeaderSorted('price', sortedColumnName, sortedDescending)}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {orderItems.data && sortOrderItems(orderItems.data).map((item: IOrderItem, index: number) => (
                    <tr key={`${item.itemLineId}${index}`}>
                      <td>{item.itemDescription}</td>
                      <td>{item.itemId}</td>
                      <td>{item.quantityOrdered}</td>
                      <td>{item.quantityShipped}</td>
                      <td>{item.itemCaseSize}</td>
                      <td>{formatCurrency(item.priceConfirmedAmount * 100)}</td>
                      <td className="view-details">
                        <button
                          className="Table-viewDetailsAction"
                          onClick={() => viewDetails(item.itemId)}
                        >
                          View Detail
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      )}
      <div className={styles.divToPrint}>
        <OrderConfirmationTemplate
          confirmation={orderConfirmation}
        />
        <EdnTemplate
          edn={orderEdn}
          orderItems={orderItems}
        />
      </div>
    </FullPageLayout>

  );
};

const orderByFunction: IOrderItemSortBy = {
  'delivered-quantity-asc': (item1: IOrderItem, item2: IOrderItem): number => item1.quantityShipped - item2.quantityShipped,
  'delivered-quantity-dsc': (item1: IOrderItem, item2: IOrderItem): number => item2.quantityShipped - item1.quantityShipped,
  'case-size-asc': (item1: IOrderItem, item2: IOrderItem): number => item1.itemCaseSize - item2.itemCaseSize,
  'case-size-dsc': (item1: IOrderItem, item2: IOrderItem): number => item2.itemCaseSize - item1.itemCaseSize,
  'order-quantity-asc': (item1: IOrderItem, item2: IOrderItem): number => item1.quantityOrdered - item2.quantityOrdered,
  'order-quantity-dsc': (item1: IOrderItem, item2: IOrderItem): number => item2.quantityOrdered - item1.quantityOrdered,
  'price-asc': (item1: IOrderItem, item2: IOrderItem): number => item1.priceConfirmedAmount - item2.priceConfirmedAmount,
  'price-dsc': (item1: IOrderItem, item2: IOrderItem): number => item2.priceConfirmedAmount - item1.priceConfirmedAmount,
  'id-asc': (item1: IOrderItem, item2: IOrderItem): number => sortStrings(item1.itemId, item2.itemId),
  'id-dsc': (item1: IOrderItem, item2: IOrderItem): number => sortStrings(item2.itemId, item1.itemId),
  'description-asc': (item1: IOrderItem, item2: IOrderItem): number =>
    sortStrings(item1.itemDescription, item2.itemDescription),
  'description-dsc': (item1: IOrderItem, item2: IOrderItem): number =>
    sortStrings(item2.itemDescription, item1.itemDescription),
};

const sortStrings = (s1: string, s2: string): number => {
  const parsed1 = s1 ? s1.toUpperCase() : '';
  const parsed2 = s2 ? s2.toUpperCase() : '';

  if (parsed1 < parsed2) {
    return -1;
  }

  if (parsed1 > parsed2) {
    return 1;
  }

  return 0;
};

const mapStateToProps = (state: IStoreState) => {
  return {
    orderItems: state.orderItems,
    orderDetails: state.orderSearch,
    retailer: state.retailerDetails.data,
    orderConfirmation: state.orderConfirmation,
    orderEdn: state.orderEdn,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getOrderItems: (order: string, retailer: string) => dispatch(orderItemsRequest(order, retailer)),
    getOrderDetails: (order: string, retailer: string) => dispatch(orderSearchRequest(order, retailer)),
    getOrderConfirmation: (order: string, retailer: string) => dispatch(orderConfirmationRequest(order, retailer)),
    getOrderEdn: (order: string, retailer: string) => dispatch(orderEdnRequest(order, retailer)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
