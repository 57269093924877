import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import { ICompleteClaimReview, IOrderClaim, IOrderClaimReview } from '../../types';

export interface IOrderClaimsResponse {
  orderClaims: IOrderClaim[];
}

export interface IOrderClaimReviewResponse {
  orderClaim: IOrderClaimReview;
}

export const submitOrderClaimApi = (params: IOrderClaim): AxiosPromise<void> => {
  return Fetch().then(axios => axios.post('orders/claims/submit', params));
};

export const getOrderClaimsApi = (resolvedClaims: boolean): AxiosPromise<IOrderClaimsResponse> => {
  return Fetch().then(axios => axios.get(`orders/claims/get?resolvedClaims=${resolvedClaims}`));
};

export const getOrderClaimApi = (claimNumber: string): AxiosPromise<IOrderClaimReviewResponse> => {
  return Fetch().then(axios => axios.get(`orders/claim/get?claimNumber=${claimNumber}`));
};

export const submitOrderClaimReviewApi = (params: ICompleteClaimReview): AxiosPromise<void> => {
  return Fetch().then(axios => axios.post('orders/claims/review/submit', params));
};
