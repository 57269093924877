import React, { Dispatch, useMemo } from 'react';
import { Button } from '@orderly/morrisons-component-library';
import { IStoreState } from '../../types';
import { connect } from 'react-redux';
import {
  viewCategorySlots,
  addToBasketText,
  requireNewSlot,
  addToBasketActive,
  allowAddToBasket,
} from '../../helpers/Basket';
import QuantityCounter from '../QuantityCounter/QuantityCounter';
import { IScannerBasketOptions } from './IScannerBasketOptionsProps';
import { updateScannedItemQuantity } from '../../actions/Products/scannedItemsAction';

const ScannerBasketOptions = ({
  itemId,
  basket,
  scannedItems,
  addToBasket,
  category,
  status,
  available,
  showLabels,
  maxOrderQuantity,
}: IScannerBasketOptions): JSX.Element => {
  const item = useMemo(
    () => {
      if (scannedItems.length > 0) {
        const item = scannedItems.find(product => product.itemId === itemId);
        if (item) {
          return item;
        }
      }
      return null;
    },
    [scannedItems, itemId],
  );

  const onQuantityChange = (itemId: number, quantity: number) => {
    handleButtonOnClick(itemId, quantity);
  };

  const handleButtonOnClick = (item: number, quantity: number) => {
    if (requireNewSlot(basket)) {
      viewCategorySlots(category);
    } else if (!basket.loading) {
      addToBasket(item, quantity);
    }
  };

  if (
    requireNewSlot(basket) ||
    !allowAddToBasket(basket, category, status, available) ||
    (item === null || item.quantity === 0)
  ) {
    return (
      <Button
        type="button"
        className={'add-to-basket tertiary'}
        text={addToBasketText(basket, category, status, itemId, available)}
        disabled={addToBasketActive(basket, category, status, available)}
        onClick={() => handleButtonOnClick(itemId, 1)}
      />
    );
  }

  return (
    <React.Fragment>
      {
        showLabels ? <span>
          <div className="quantity">
            <span>Quantity</span>
            <QuantityCounter
              itemId={item.itemId}
              baseQuantity={item.quantity}
              onQuantityChange={(itemId, quantity) => onQuantityChange(itemId, quantity)}
              maxOrderQuantity={maxOrderQuantity}
            />
          </div>
        </span> :
          <QuantityCounter
            itemId={item.itemId}
            baseQuantity={item.quantity}
            onQuantityChange={(itemId, quantity) => onQuantityChange(itemId, quantity)}
            maxOrderQuantity={maxOrderQuantity}
          />
      }
    </React.Fragment>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    basket: state.basket,
    scannedItems: state.scannedItems,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    addToBasket: (itemId: number, quantity: number) => dispatch(updateScannedItemQuantity(itemId, quantity)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScannerBasketOptions);
