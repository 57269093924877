import { Dispatch } from 'redux';
import { ICompleteClaimReview, IOrderClaim } from '../../../types';
import {
  IOrderClaimsResponse,
  IOrderClaimReviewResponse,
  getOrderClaimsApi,
  submitOrderClaimApi,
  getOrderClaimApi,
  submitOrderClaimReviewApi,
} from '../../../api/Orders/orderClaimsApi';
import { toastr } from 'react-redux-toastr';
import { AxiosResponse } from 'axios';

export const SUBMIT_ORDER_CLAIMS_REQUEST = 'SUBMIT_ORDER_CLAIMS_REQUEST';
export const SUBMIT_ORDER_CLAIMS_RESPONSE = 'SUBMIT_ORDER_CLAIMS_RESPONSE';
export const SUBMIT_ORDER_CLAIMS_ERROR = 'SUBMIT_ORDER_CLAIMS_ERROR';

export const GET_ORDER_CLAIMS_REQUEST = 'GET_ORDER_CLAIMS_REQUEST';
export const GET_ORDER_CLAIMS_RESPONSE = 'GET_ORDER_CLAIMS_RESPONSE';
export const GET_ORDER_CLAIMS_ERROR = 'GET_ORDER_CLAIMS_ERROR';

export const GET_ORDER_CLAIM_REQUEST = 'GET_ORDER_CLAIM_REQUEST';
export const GET_ORDER_CLAIM_RESPONSE = 'GET_ORDER_CLAIM_RESPONSE';
export const GET_ORDER_CLAIM_ERROR = 'GET_ORDER_CLAIM_ERROR';

export const SUBMIT_ORDER_CLAIM_REVIEW_REQUEST = 'SUBMIT_ORDER_CLAIM_REVIEW_REQUEST';
export const SUBMIT_ORDER_CLAIM_REVIEW_RESPONSE = 'SUBMIT_ORDER_CLAIM_REVIEW_RESPONSE';
export const SUBMIT_ORDER_CLAIM_REVIEW_ERROR = 'SUBMIT_ORDER_CLAIM_REVIEW_ERROR';

export const submitOrderClaimRequest = (
  claim: IOrderClaim,
): ((dispatch: Dispatch) => Promise<any>) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SUBMIT_ORDER_CLAIMS_REQUEST,
      parameters: claim,
    });

    try {
      const response = await submitOrderClaimApi(claim);

      if (response.status === 200) {
        toastr.success('Order Claim Submitted', 'Your claim has been successfully submitted.');
        dispatch({
          type: SUBMIT_ORDER_CLAIMS_RESPONSE,
        });
      } else {
        submitOrderClaimError(dispatch);
      }
    } catch (error) {
      console.log(error);
      submitOrderClaimError(dispatch);
    }
  };
};

const submitOrderClaimError = (dispatch: any, message: string = null) =>
  dispatch({
    type: SUBMIT_ORDER_CLAIMS_ERROR,
    response: message,
  });

export const getOrderClaimsRequest = (resolvedClaims: boolean): any => {
  return (dispatch: any) => {
    dispatch({
      type: GET_ORDER_CLAIMS_REQUEST,
    });

    return getOrderClaimsApi(resolvedClaims)
      .then((response: AxiosResponse<IOrderClaimsResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: GET_ORDER_CLAIMS_RESPONSE,
            response: response.data.orderClaims,
          });
        } else {
          getOrderClaimsError(dispatch, response.data as unknown as string);
        }
      })
      .catch((err: any) => {
        console.log(err);
        getOrderClaimsError(dispatch);
      });
  };
};

const getOrderClaimsError = (dispatch: any, message: string = null) =>
  dispatch({
    type: GET_ORDER_CLAIM_ERROR,
    response: message,
  });

export const getSingleOrderClaimRequest = (claimNumber: string): any => {
  return (dispatch: any) => {
    dispatch({
      type: GET_ORDER_CLAIM_REQUEST,
    });

    return getOrderClaimApi(claimNumber)
      .then((response: AxiosResponse<IOrderClaimReviewResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: GET_ORDER_CLAIM_RESPONSE,
            response: response.data.orderClaim,
          });
        } else {
          getSingleOrderClaimError(dispatch, response.data as unknown as string);
        }
      })
      .catch((err: any) => {
        console.log(err);
        getSingleOrderClaimError(dispatch);
      });
  };
};

const getSingleOrderClaimError = (dispatch: any, message: string = null) =>
  dispatch({
    type: GET_ORDER_CLAIM_ERROR,
    response: message,
  });

export const submitOrderClaimReviewRequest = (
  claimFeedback: ICompleteClaimReview,
): ((dispatch: Dispatch) => Promise<any>) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SUBMIT_ORDER_CLAIM_REVIEW_REQUEST,
      parameters: claimFeedback,
    });

    try {
      const response = await submitOrderClaimReviewApi(claimFeedback);

      if (response.status === 200) {
        toastr.success(
          'Order Claim Review Submitted',
          'Your response has been successfully submitted.',
        );
        dispatch({
          type: SUBMIT_ORDER_CLAIM_REVIEW_RESPONSE,
        });
      } else {
        submitOrderClaimReviewError(dispatch);
      }
    } catch (error) {
      console.log(error);
      submitOrderClaimReviewError(dispatch);
    }
  };
};

const submitOrderClaimReviewError = (dispatch: any, message: string = null) =>
  dispatch({
    type: SUBMIT_ORDER_CLAIM_REVIEW_ERROR,
    response: message,
  });
