import React from 'react';
import { Button } from '@orderly/morrisons-component-library';
import { navigateTo } from '../../../../routing/Navigation';
import styles from './HeaderControls.module.scss';

interface IHeaderControlsProps {
  productsLoaded: boolean;
  basketLoading: boolean;
  shoppingListId: number;
  onAddAllToBasket: () => void;
  onSubmitProductBarcodes: () => void;
}

const HeaderControls: React.FC<IHeaderControlsProps> = ({
  productsLoaded,
  basketLoading,
  shoppingListId,
  onAddAllToBasket,
  onSubmitProductBarcodes,
}) => (
  <div className={styles.container}>
    {productsLoaded && (
      <>
        {
          !basketLoading &&
          < Button
            type="button"
            className="tertiary"
            text="Add all items to basket"
            onClick={onAddAllToBasket}
            disabled={basketLoading}
          />
        }
        {
          shoppingListId > 0 && (
            <Button
              type="button"
              className="tertiary"
              text="Add all items to shopping list"
              onClick={onSubmitProductBarcodes}
              disabled={basketLoading}
            />
          )
        }
      </>
    )}
    <Button
      onClick={() => navigateTo('/scanner/user-guide')}
      text="Help"
      className="primary"
      type="button"
    />
  </div>
);

export default HeaderControls;
