export const QUERY_KEYS = {
  ADMIN_BANNERS: 'ADMIN_BANNERS',
  ADMIN_BANNER: 'ADMIN_BANNER',
  BANNERS: 'BANNERS',
  FINANCIAL_STATEMENTS: 'FINANCIAL_STATEMENTS',
  FINANCIAL_INVOICES: 'FINANCIAL_INVOICES',
  DOWNLOAD_FINANCIAL_INVOICE: 'DOWNLOAD_FINANCIAL_INVOICE',
  PRODUCT_BARCODE_LOOKUP: 'PRODUCT_BARCODE_LOOKUP',
  CONFIRMED_ORDERS: 'CONFIRMED_ORDERS',
} as const;
