import React from 'react';
import styles from './SkeletonLoader.module.scss';

function SkeletonLoader(): JSX.Element {
  return (
    <div className={`${styles.skeleton} ${styles.animate}`} />
  );
}

export default SkeletonLoader;
