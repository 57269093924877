import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { IStoreState, IUserDetails } from './types';
import { userLogoutRequest } from './actions/Logout/userLogoutAction';
import { ILogoutParams } from './api/Logout/ILogoutParams';
import { navigateTo } from './routing/Navigation';
import { isLoggedIn, isStaff } from './helpers/Users/UserHelper';
import { updateUserDetails } from './actions/userDetailsAction';
import { useQueryClient } from '@tanstack/react-query';

interface IInactivityLogout {
  userDetails?: IUserDetails;
  updateUserDetails?: () => void;
  logout?: (params: ILogoutParams, success: () => void) => void;
}

const InactivityLogout: React.FC<IInactivityLogout> = ({
  userDetails,
  updateUserDetails,
  logout,
}) => {
  const idleTimeout15Minutes = 15 * 60 * 1000;
  const [idleTimeout, setIdleTimeout] = useState(idleTimeout15Minutes);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (userDetails.expiration && userDetails.issuedAt) {
      const expirationTime = new Date(userDetails.expiration).getTime();
      const issuedAtTime = new Date(userDetails.issuedAt).getTime();
      setIdleTimeout(expirationTime - issuedAtTime);
    }
  }, []);

  const handleOnIdle = () => {
    const loggedIn = isLoggedIn(userDetails);
    if (!loggedIn) {
      return;
    }
    queryClient.clear();

    logout(
      {
        userId: userDetails.userId,
        refreshToken: userDetails.refreshToken,
      },
      () => {
        if (updateUserDetails) {
          updateUserDetails();
        }
        if (isStaff(userDetails)) {
          navigateTo('/admin/home');
        } else {
          navigateTo('/');
        }
      },
    );
  };

  useIdleTimer({
    timeout: idleTimeout,
    onIdle: handleOnIdle,
    debounce: 500,
    events: ['mousemove', 'keydown', 'mousedown', 'touchstart'],
  });

  return null;
};

const mapStateToProps = (state: IStoreState) => ({
  userDetails: state.userDetails,
});

const mapDispatchToProps = (dispatch: any) => ({
  logout: (params: ILogoutParams, success: () => void) =>
    dispatch(userLogoutRequest(params, success)),
  updateUserDetails: () => dispatch(updateUserDetails({ userId: -1 })),
});

export default connect(mapStateToProps, mapDispatchToProps)(InactivityLogout);
