import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import HomePage from '../pages/HomePage/HomePage';
import LandingPage from '../pages/LandingPage/LandingPage';
import Login from '../pages/Login/Login';
import PasswordReset from '../pages/PasswordReset/PasswordReset';
import PasswordResetConfirmation from '../pages/PasswordResetConfirmation/PasswordResetConfirmation';
import Product from '../pages/Product/Product';
import Catalogue from '../pages/Catalogue/Catalogue';
import PrivateRoute from './PrivateRoute';
import AdminRoute from './AdminRoute';
import AdminLoginComponent from '../pages/AdminLogin/AdminLogin';
import { CreateUser, Home, ManageUsers, Orders as AdminOrders, RetailerProductPricing, Banners } from '../pages/Admin';
import * as AccountManager from '../pages/AccountManager';
import Documents from '../pages/Documents/Documents';
import SelectRetailerComponent from '../pages/Admin/SelectRetailer/SelectRetailer';
import SelectedRetailerComponent from '../pages/Admin/SelectRetailer/selected/SelectedRetailer';
import Register from '../pages/Register/Register';
import AdminDocuments from '../pages/Admin/AdminDocuments/AdminDocuments';
import DocumentsUpload from '../pages/Admin/DocumentsUpload/DocumentsUpload';
import CreateFolder from '../pages/Admin/CreateFolder/CreateFolder';
import UpdateFolder from '../pages/Admin/UpdateFolder/UpdateFolder';
import EditDocument from '../pages/Admin/DocumentsEdit/DocumentsEdit';
import Search from '../pages/Search/Search';
import Orders from '../pages/Orders/Orders';
import OrderDetails from '../pages/Orders/OrderDetails/OrderDetails';
import { RaiseClaim, ViewClaims, ReviewClaim } from '../pages/Orders/OrderClaims';
import PageNotFound from '../pages/PageNotFound/PageNotFound';
import { FAQs } from '../pages/Footer/FAQs/FAQs';
import { TermsOfUse } from '../pages/Footer/TermsOfUse/TermsOfUse';
import { TermsConditions } from '../pages/Footer/TermsConditions/TermsConditions';
import MyAccount from '../pages/MyAccount/MyAccount';
import Basket from '../pages/Basket/Basket';
import ProductExclusions from '../pages/Admin/ProductExclusions/ProductExclusions';
import ManageTransportCosts from '../pages/Admin/ManageTransportCosts/ManageTransportCosts';
import AccountManagerRoute from './AccountManagerRoute';
import CustomerServices from '../pages/CustomerServices/CustomerServices';
import Rebates from '../pages/Rebates/Rebates';
import AdminPrintshop from '../pages/Admin/Printshop/Printshop';
import Printshop from '../pages/Printshop/Printshop';
import AdminStoreDocuments from '../pages/Admin/AdminStoreDocuments';
import StoreDocuments from '../pages/StoreDocuments';
import SupplyTeamRoute from './SupplyTeamRoute';
import * as SupplyTeam from '../pages/SupplyTeam';
import * as LogisticsTeam from '../pages/LogisticsTeam';
import LogisticsTeamRoute from './LogisticsTeamRoute';
import StockAllocation from '../pages/SupplyTeam/StockAllocation/StockAllocation';
import OrderDispatch from '../pages/LogisticsTeam/OrderDispatch/OrderDispatch';
import OrderDispatchReview from '../pages/Orders/OrderDispatchReview/DispatchedOrdersReview';
import CopyOrder from '../pages/CopyOrder';
import POSCreator from '../pages/Printshop/POSCreator/POSCreator';
import UploadShoppingList from '../pages/Admin/ShoppingLists/UploadShoppingList/UploadShoppingList';
import ManageCustomerGroups from '../pages/Admin/ManageCustomerGroups/ManageCustomerGroups';
import BarcodeScannerPage from '../pages/BarcodeScannerPage/BarcodeScannerPage';
import * as CustomerPresales from '../pages/Presales';
import * as AdminPresales from '../pages/Admin/Presales';
import ManageShoppingLists from '../pages/Admin/ShoppingLists/ManageShoppingLists/ManageShoppingLists';
import AdminTradingPacks from '../pages/Admin/AdminTradingPacks';
import EditTradingPack from '../pages/Admin/EditTradingPack';
import Deliveries from '../pages/Deliveries';
import EditBanner from '../pages/Admin/EditBanner';
import Invoices from '../pages/FinancialDocuments/Invoices';
import Statements from '../pages/FinancialDocuments/Statements';
import { Scanner, ScannerLanding, ScannerSetup, ScannerUserGuide } from '../pages/Scanner';

const Routes = () => (
  <Switch>
    {/* Any non-private routes need to be added to
      ./components/Header/Header publicRoutes array  */}
    <Route exact path="/" component={LandingPage} />
    <Route exact path="/admin" component={AdminLoginComponent} />
    <Route exact path="/admin/login" component={AdminLoginComponent} />
    <Route exact path="/account-manager/login" component={AdminLoginComponent} />
    <Route exact path="/supply-team/login" component={AdminLoginComponent} />
    <Route exact path="/logistics-team/login" component={AdminLoginComponent} />
    <Route exact path="/confirm-password-reset" component={PasswordResetConfirmation} />
    <Route exact path="/password-reset" component={PasswordReset} />
    <Route exact path="/register/:userId/:registrationId" component={Register} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/faqs" component={FAQs} />
    <Route exact path="/terms-of-use" component={TermsOfUse} />
    <Route exact path="/terms-conditions" component={TermsConditions} />

    {/* Logged-in user pages */}
    <PrivateRoute exact path="/scanner" component={ScannerLanding} />
    <PrivateRoute exact path="/scanner/setup" component={ScannerSetup} />
    <PrivateRoute exact path="/scanner/user-guide" component={ScannerUserGuide} />
    <PrivateRoute exact path="/scanner/place-order" component={Scanner} />

    <PrivateRoute exact path="/documents" component={Documents} />
    <PrivateRoute exact path="/home" component={HomePage} />
    <PrivateRoute exact path="/catalogue" component={Catalogue} />
    <PrivateRoute exact path="/product/:id" component={Product} />
    <PrivateRoute exact path="/search" component={Search} />
    <PrivateRoute exact path="/orders" component={Orders} />
    <PrivateRoute exact path="/orders/copy" component={CopyOrder} />
    <PrivateRoute exact path="/orders/:orderId" component={OrderDetails} />
    <PrivateRoute exact path="/orders/claims/orderNumber=:orderId" component={RaiseClaim} />
    <PrivateRoute exact path="/my-account" component={MyAccount} />
    <PrivateRoute exact path="/basket" component={Basket} />
    <PrivateRoute exact path="/customer-services" component={CustomerServices} />
    <PrivateRoute
      exact
      retailerMeta={{ RebatesEnabled: 'true' }}
      path="/rebates"
      component={Rebates}
    />
    <PrivateRoute
      exact
      retailerMeta={{ PrintshopEnabled: 'true' }}
      path="/printshop"
      component={Printshop}
    />
    <PrivateRoute
      exact
      retailerMeta={{ PlanogramsEnabled: 'true' }}
      path="/store-documents"
      component={StoreDocuments}
    />
    <PrivateRoute exact path="/pos-creator" component={POSCreator} />
    <PrivateRoute exact path="/barcode-scanner" component={BarcodeScannerPage} />
    <PrivateRoute exact path="/presales" component={CustomerPresales.Home} />
    <PrivateRoute
      exact
      path="/presales/campaign:campaignId/store:storeId"
      component={CustomerPresales.CampaignEntry}
    />
    <PrivateRoute exact path="/deliveries" component={Deliveries} />
    <PrivateRoute exact path="/invoices" component={Invoices} />
    <PrivateRoute exact path="/statements" component={Statements} />

    {/* Logged-in admin pages */}
    <AdminRoute exact path="/admin/documents/:retailerId" component={AdminDocuments} />
    <AdminRoute exact path="/admin/printshop" component={AdminPrintshop} />
    <AdminRoute
      path={['/admin/documents/:retailerId/upload', '/admin/printshop/upload']}
      component={DocumentsUpload}
    />
    <AdminRoute
      path={['/admin/documents/:retailerId/edit/:documentId', '/admin/printshop/edit/:documentId']}
      component={EditDocument}
    />
    <AdminRoute
      path={['/admin/documents/:retailerId/create-folder', '/admin/printshop/create-folder']}
      component={CreateFolder}
    />
    <AdminRoute
      path={['/admin/documents/:retailerId/update-folder', '/admin/printshop/update-folder']}
      component={UpdateFolder}
    />
    <AdminRoute exact path="/admin/home" component={Home} />
    <AdminRoute exact path="/admin/retailer" component={SelectRetailerComponent} />
    <AdminRoute exact path="/admin/retailer/:id" component={SelectedRetailerComponent} />
    <AdminRoute exact path="/admin/users" component={ManageUsers} />
    <AdminRoute exact path="/admin/users/create" component={CreateUser} />
    <AdminRoute exact path="/admin/users/update/:id" component={CreateUser} />
    <AdminRoute exact path="/admin/admin-users" component={ManageUsers} />
    <AdminRoute exact path="/admin/admin-users/create" component={CreateUser} />
    <AdminRoute exact path="/admin/admin-users/update/:id" component={CreateUser} />
    <AdminRoute exact path="/admin/products/exclusions" component={ProductExclusions} />
    <AdminRoute exact path="/admin/transport-costs" component={ManageTransportCosts} />
    <AdminRoute exact path="/admin/orders" component={AdminOrders} />
    <AdminRoute exact path="/admin/stock-allocation" component={StockAllocation} />
    <AdminRoute exact path="/admin/order-dispatch" component={OrderDispatch} />
    <AdminRoute exact path="/admin/order-dispatch-review" component={OrderDispatchReview} />
    <AdminRoute exact path="/admin/manage-shopping-lists" component={ManageShoppingLists} />
    <AdminRoute exact path="/admin/upload-shopping-lists" component={UploadShoppingList} />
    <AdminRoute exact path="/admin/order-claims-review" component={ViewClaims} />
    <AdminRoute exact path="/admin/presales-campaigns" component={AdminPresales.PresaleCampaignsList} />
    <AdminRoute
      exact
      path="/admin/presale-campaigns/create"
      component={AdminPresales.CreateCampaign}
    />
    <AdminRoute
      exact
      path="/admin/presales-campaigns/campaign/:campaignId"
      component={AdminPresales.EditCampaign}
    />
    <AdminRoute
      exact
      path="/admin/presales-campaigns/preview/campaign/:campaignId"
      component={AdminPresales.PreviewCampaign}
    />
    <AdminRoute
      exact
      path="/admin/order-claims-review/claimNumber=:claimNumber"
      component={ReviewClaim}
    />
    <AdminRoute exact path="/admin/store-documents/:id" component={AdminStoreDocuments} />
    <AdminRoute exact path="/admin/pos-creator" component={POSCreator} />
    <AdminRoute exact path="/admin/customer-groups" component={ManageCustomerGroups} />
    <AdminRoute exact path="/admin/trading-packs" component={AdminTradingPacks} />
    <AdminRoute
      exact
      path={[
        '/admin/trading-packs/:id',
        '/admin/trading-packs/create',
      ]}
      component={EditTradingPack}
    />
    <AdminRoute exact path="/admin/retailer-pricing/:id" component={RetailerProductPricing} />
    <AdminRoute exact path="/admin/manage-banners" component={Banners} />
    <AdminRoute exact path="/admin/manage-banners/:id" component={EditBanner} />

    {/* Logged-in account manager pages */}
    <Redirect exact from="/account-manager/" to="/account-manager/home" />
    <AccountManagerRoute exact path="/account-manager/home" component={AccountManager.Home} />
    <AccountManagerRoute
      exact
      path="/account-manager/users"
      component={AccountManager.ManageUsers}
    />
    <AccountManagerRoute exact path="/account-manager/orders" component={AccountManager.Orders} />
    <AccountManagerRoute
      exact
      path="/account-manager/order-dispatch-review"
      component={OrderDispatchReview}
    />
    <AccountManagerRoute exact path="/account-manager/order-claims-review" component={ViewClaims} />
    <AccountManagerRoute
      exact
      path="/account-manager/order-claims-review/claimNumber=:claimNumber"
      component={ReviewClaim}
    />
    <AccountManagerRoute exact path="/account-manager/retailer-pricing/:id" component={RetailerProductPricing} />

    {/* Logged-in supply team pages */}
    <Redirect exact from="/supply-team/" to="/supply-team/home" />
    <SupplyTeamRoute exact path="/supply-team/home" component={SupplyTeam.Home} />
    <SupplyTeamRoute exact path="/supply-team/stock-allocation" component={StockAllocation} />
    {/* Logged-in logistics team pages */}
    <Redirect exact from="/logistics-team/" to="/logistics-team/home" />
    <LogisticsTeamRoute exact path="/logistics-team/home" component={LogisticsTeam.Home} />
    <LogisticsTeamRoute exact path="/logistics-team/order-dispatch" component={OrderDispatch} />
    {/* 404 */}
    <Route component={PageNotFound} />
  </Switch>
);

export default Routes;
