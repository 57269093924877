import React from 'react';

interface IMissingBarcodeMessageProps {
  missingBarcodes: string[];
}

function MissingBarcodeMessage({
  missingBarcodes,
}: Readonly<IMissingBarcodeMessageProps>): JSX.Element {
  return (
    <div>
      <p>
        {missingBarcodes.length} {missingBarcodes.length === 1 ? 'barcode was' : 'barcodes were'}{' '}
        invalid, please review and re-upload:
      </p>
      <ul>
        {
          missingBarcodes.map(barcode => (
            <li key={`missing_barcode_row_${barcode}`}>
              {barcode}
            </li>
          ))
        }
      </ul>
    </div>
  );
}

export default MissingBarcodeMessage;
