import { Reducer } from 'redux';
import { IPersistedOrderFilters } from '../../../types';

const initialState: IPersistedOrderFilters = {
  selectedDate: new Date(),
  statusFilter: 0,
  dateFilter: 0,
  currentPage: 1,
  selectedStore: null,
};

const persistOrderFilters: Reducer<IPersistedOrderFilters> =
(state: IPersistedOrderFilters = initialState, action: any): IPersistedOrderFilters => {
  if (action.type === 'ORDERS_LIST_FILTERS_PERSIST') {
    return {
      selectedDate: action.payload.selectedDate,
      statusFilter: action.payload.statusFilter,
      dateFilter: action.payload.dateFilter,
      currentPage: action.payload.currentPage,
      selectedStore: action.payload.selectedStore,
    };
  }
  return state;
};

export default persistOrderFilters;
