import React, { useState, Dispatch, useEffect, useMemo } from 'react';
import { Dropdown, Button, Card, StyledCalendar } from '@orderly/morrisons-component-library';
import { navigateTo } from '../../routing/Navigation';
import { connect } from 'react-redux';
import retailerDetailsAction from '../../actions/retailerDetailAction';
import { IPagedData, IPersistedOrderFilters, IRetailerStore, ISimpleOrder, IStoreState } from '../../types';
import LoadingThrobber from '../../components/LoadingThrobber/LoadingThrobber';
import { IOrderProps } from './IOrderProps';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../constants';
import { FullPageLayout } from '../../layouts';
import { getOrdersPage } from '../../api/Orders/ordersApi';
import Table from '../../components/Table/Table';
import { formatDateString, formatShortDateString } from '../../helpers/Format';
import styles from './Orders.module.scss';
import { mapCategory } from '../../helpers/Category/mapCategory';
import { Pagination } from 'react-bootstrap';
import { persistOrderFiltersAction } from '../../actions/Orders/OrderList/persistOrderFiltersAction';
import { mapOrderStatus } from '../../helpers/Orders/MapOrders';
import { amendOrderRequest } from '../../actions/Basket/amendOrderAction';
import { IAmendOrderParams } from '../../api/Basket/AmendOrder/IAmendOrderParams';

enum StatusFilters {
  SUBMITTED = 0,
  PENDING = 1,
}

enum DateFilters {
  LAST_FIFTY = 0,
  ORDER_DATE = 1,
  DELIVERY_DATE = 2,
}

const OrderClaimButton = ({
  order,
}: { order: ISimpleOrder }): JSX.Element => {
  if (order.claimEligable === null) {
    return null;
  }

  if (!order.claimEligable) {
    return (
      <>
        <span className={`icon-tick ${styles.claimRaised}`} />
        <span className="underline">Claim raised</span>
      </>
    );
  }

  return (
    <button
      className="Table-iconAction"
      onClick={() => navigateTo(`/orders/claims/orderNumber=${order.orderNumber}`)}
    >
      <span className="icon-text-file" /><span className="underline">Raise claim</span>
    </button>
  );
};

const Orders = ({
  userStores,
  persistedOrderListFilters,
  persistOrderListFilters,
  amendOrder,
}: IOrderProps) => {
  const formatter = new Intl.DateTimeFormat('en-GB');
  const { dateFilter, statusFilter, currentPage, selectedDate, selectedStore } = persistedOrderListFilters;
  const [totalPages, setTotalPages] = useState<number>(1);
  const searchByDeliverAt = dateFilter === DateFilters.DELIVERY_DATE;
  const searchDate = dateFilter === DateFilters.LAST_FIFTY ? null : formatter.format(selectedDate);
  const pending = statusFilter === StatusFilters.PENDING;
  const itemsPerPage = 25;

  const { data: ordersPage, isLoading } = useQuery<IPagedData<ISimpleOrder>, Error>(
    [QUERY_KEYS.CONFIRMED_ORDERS, selectedStore, currentPage, searchDate, searchByDeliverAt, pending],
    () => getOrdersPage({
      searchByDeliverAt,
      pending,
      currentPage,
      dateFilter: searchDate,
      storeId: selectedStore,
      pageSize: itemsPerPage,
    }),
    {
      enabled: selectedStore !== null,
    },
  );

  useEffect(
    () => {
      if (selectedStore != null) {
        return;
      }

      if (userStores && userStores.stores.length > 0) {
        setSelectedStore(userStores.stores[0].externalId);
      }
    },
    [userStores, selectedStore],
  );

  const orders = useMemo(
    () => {
      if (ordersPage && ordersPage.data) {
        return ordersPage.data;
      }

      return [];
    },
    [ordersPage, selectedStore, currentPage, searchDate, searchByDeliverAt, pending],
  );

  useEffect(
    () => {
      if (ordersPage) {
        const total = Math.ceil(ordersPage.totalData / itemsPerPage);
        setTotalPages(total);
      }
    },
    [ordersPage, selectedStore, currentPage, searchDate, searchByDeliverAt, pending],
  );

  const selectStore = (externalStoreId: number) => {
    setCurrentPage(1);
    setSelectedStore(externalStoreId);
  };

  const setSelectedStore = (storeId: number) => {
    persistOrderListFilters({
      selectedDate,
      statusFilter,
      dateFilter,
      currentPage,
      selectedStore: storeId,
    });
  };

  const setCurrentPage = (newPage: number) => {
    persistOrderListFilters({
      selectedStore,
      selectedDate,
      statusFilter,
      dateFilter,
      currentPage: newPage,
    });
  };

  const onStatusChange = (submitted: boolean) => {
    const newStatus = submitted ? StatusFilters.SUBMITTED : StatusFilters.PENDING;
    let newDateFilter = dateFilter;

    if (newStatus === StatusFilters.PENDING && newDateFilter === DateFilters.LAST_FIFTY) {
      newDateFilter = DateFilters.DELIVERY_DATE;
    }

    persistOrderListFilters({
      selectedStore,
      selectedDate,
      statusFilter: newStatus,
      dateFilter: newDateFilter,
      currentPage: 1,
    });
  };

  const amendPendingOrder = ({
    id,
  }: ISimpleOrder) => {
    amendOrder({
      OrderId: id,
    })
      .then(
        () => navigateTo('/basket'),
        (error: any) => console.log('An error has occurred ', error),
      );
  };

  const onCalendarChange = (newDate: Date) => {
    let newDateFilter = dateFilter;

    if (dateFilter === DateFilters.LAST_FIFTY) {
      newDateFilter = DateFilters.DELIVERY_DATE;
    }

    persistOrderListFilters({
      selectedStore,
      statusFilter,
      dateFilter: newDateFilter,
      selectedDate: newDate,
      currentPage: 1,
    });
  };

  return (
    <FullPageLayout
      heading="My Orders"
      breadcrumbs={[
        { key: 0, text: 'My Orders', url: '/orders' },
        { key: 1, text: `${pending ? 'Pending' : 'Submitted'}`, url: '/orders' },
      ]}
      headerAdditionalContent={
        (userStores != null && userStores.stores != null && userStores.stores.length > 1) &&
        <Dropdown
          className={styles.storeSelect}
          label=""
          name="store-sort"
          onChange={value => selectStore(value.value as number)}
          selectedValue={selectedStore}
          options={userStores.stores.map((store: IRetailerStore) => {
            return {
              value: store.externalId,
              label: `${store.name} [${store.externalId}]`,
            };
          })}
        />
      }
    >
      {isLoading && <LoadingThrobber />}
      <div className={styles.container}>
        <div className={styles.filters}>
          <Card className={styles.card}>
            <div>
              <span>Status</span>
              <div className={styles.viewMode}>
                <Button
                  type="button"
                  className={`${statusFilter === StatusFilters.SUBMITTED ? 'secondary' : 'link'} ${styles.button}`}
                  text="Submitted"
                  onClick={() => onStatusChange(true)}
                />
                <Button
                  type="button"
                  className={`${statusFilter === StatusFilters.PENDING ? 'secondary' : 'link'} ${styles.button}`}
                  text="Pending"
                  onClick={() => onStatusChange(false)}
                />
              </div>
            </div>
            <hr />
            <div>
              <span>Date</span>
              <div className={styles.viewMode}>
                <Button
                  disabled={pending}
                  type="button"
                  className={`
                    ${dateFilter === DateFilters.LAST_FIFTY ? 'secondary' : 'link'}
                    ${styles.button} ${styles.last50}
                  `}
                  text="Last 50"
                  onClick={
                    () => persistOrderListFilters({
                      selectedStore,
                      selectedDate,
                      statusFilter,
                      dateFilter: DateFilters.LAST_FIFTY,
                      currentPage: 1,
                    })
                  }
                />
                <Button
                  type="button"
                  className={`${dateFilter === DateFilters.ORDER_DATE ? 'secondary' : 'link'} ${styles.button}`}
                  text="Order Date"
                  onClick={
                    () => persistOrderListFilters({
                      selectedStore,
                      selectedDate,
                      statusFilter,
                      dateFilter: DateFilters.ORDER_DATE,
                      currentPage: 1,
                    })
                  }
                />
                <Button
                  type="button"
                  className={`${dateFilter === DateFilters.DELIVERY_DATE ? 'secondary' : 'link'} ${styles.button}`}
                  text="Delivery Date"
                  onClick={
                    () => persistOrderListFilters({
                      selectedStore,
                      selectedDate,
                      statusFilter,
                      dateFilter: DateFilters.DELIVERY_DATE,
                      currentPage: 1,
                    })
                  }
                />
              </div>
            </div>
            <div>
              <StyledCalendar
                onChange={newDate => onCalendarChange(newDate as Date)}
                value={selectedDate}
                selectRange={false}
              />
            </div>
          </Card>
        </div>
        <div className={styles.content}>
          <Table className="">
            <thead>
              <tr>
                <th>Order Number</th>
                <th>Order Date</th>
                {pending && <th>Lock Date</th>}
                <th>Delivery Date</th>
                <th>Category</th>
                <th>Items QT</th>
                <th>Status</th>
                <th />
                <th />
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              {
                isLoading &&
                <tr>
                  <td colSpan={pending ? 10 : 9}>Loading...</td>
                </tr>
              }
              {
                (!isLoading && orders.length === 0) &&
                <tr>
                  <td colSpan={pending ? 10 : 9}>No orders found</td>
                </tr>
              }
              {
                orders.map((order: ISimpleOrder) => {
                  return (
                    <tr key={order.orderNumber}>
                      <td>{order.orderNumber}</td>
                      <td>{formatShortDateString(order.placedAt)}</td>
                      {pending && <td>{formatDateString(order.lockAt)}</td>}
                      <td>{formatShortDateString(order.deliverAt)}</td>
                      <td>{mapCategory(order.catgegory)}</td>
                      <td>{order.quantityOrdered}</td>
                      <td>{mapOrderStatus(order.status)}</td>
                      {
                        pending &&
                        <td>
                          {
                            !order.locked &&
                            <button
                              className="Table-iconAction"
                              onClick={() => amendPendingOrder(order)}
                            >
                              <span className="icon-Edit" /><span className="underline">Amend order</span>
                            </button>
                          }
                        </td>
                      }
                      {
                        !pending &&
                        <td>
                          <button
                            className="Table-viewDetailsAction"
                            onClick={() => navigateTo(`/orders/${order.orderNumber}`)}
                          >
                            View Detail
                          </button>
                        </td>
                      }
                      <td>
                        {
                          order.copyable &&
                          <button
                            className="Table-iconAction"
                            onClick={() => navigateTo(`/orders/copy?orderNumber=${order.orderNumber}`)}
                          >
                            <span className="icon-copy" /><span className="underline">Copy order</span>
                          </button>
                        }
                      </td>
                      <td>
                        {
                          order.trackable &&
                          <button
                            className="Table-iconAction"
                            onClick={() =>
                              navigateTo(
                                'https://mysctrackandtrace.dhl.com/cview/search?query=' +
                                `${order.orderNumber}&country=GB&source=MySC`,
                              )
                            }
                          >
                            <span className="icon-truck" /><span className="underline">Track order</span>
                          </button>
                        }
                      </td>
                      <td>
                        <OrderClaimButton
                          order={order}
                        />
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </Table>
          {
            (totalPages > 1 && !isLoading) &&
            <Pagination className={styles.tablePagination}>
              <Button
                className="tertiary"
                type="submit"
                text="Previous"
                onClick={() => setCurrentPage(Math.max(currentPage - 1, 1))}
                disabled={currentPage === 1}
              />
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <Button
                className="tertiary"
                type="submit"
                text="Next"
                onClick={() => setCurrentPage(Math.min(currentPage + 1, totalPages))}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          }
        </div>
      </div>
    </FullPageLayout>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    retailerDetails: state.retailerDetails.data,
    userStores: state.userStores.data,
    persistedOrderListFilters: state.persistedOrderListFilters,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    amendOrder: (parameters: IAmendOrderParams) => dispatch(amendOrderRequest(parameters)),
    getRetailerDetails: () => dispatch(retailerDetailsAction()),
    persistOrderListFilters: (params: IPersistedOrderFilters) =>
      dispatch(persistOrderFiltersAction(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
