import Fetch from '../apiConfig/apiConfig';
import { IProductBarcodeSearchResult } from '../../types';

interface IBarcdeLookupResponse {
  result: IProductBarcodeSearchResult;
}

export const getProductsByBarcode = async (barcodes: string): Promise<IProductBarcodeSearchResult> => {
  const axios = await Fetch();
  const { data } = await axios.get<IBarcdeLookupResponse>(`products/barcode?barcodes=${barcodes}`);

  return data.result;
};
