import React, { useRef, useEffect } from 'react';
import styles from './ExpandableSection.module.scss';
import { Card } from '@orderly/morrisons-component-library';
import { CompressLines, ExpandLines } from 'iconoir-react';

interface IExpandableSectionProps {
  title: string;
  children: React.ReactNode;
  isExpanded: boolean;
  toggleExpand: () => void;
}

const ExpandableSection: React.FC<IExpandableSectionProps> = ({ title, children, isExpanded, toggleExpand }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = React.useState<number | 'auto'>(0);

  useEffect(() => {
    const updateHeight = () => {
      if (contentRef.current) {
        setContentHeight(contentRef.current.scrollHeight);
      }
    };

    if (isExpanded) {
      updateHeight();
    }

    const images: any[] | NodeListOf<HTMLImageElement> = (
      contentRef && contentRef.current && contentRef.current.querySelectorAll('img')
    ) || [];
    images.forEach((img: HTMLImageElement) => img.addEventListener('load', updateHeight));

    return () => {
      images.forEach((img: HTMLImageElement) => img.removeEventListener('load', updateHeight));
    };
  }, [isExpanded]);

  return (
    <Card className={styles.container}>
      <div
        className={`${styles.header} ${!isExpanded ? styles.collapsed : ''}`}
        onClick={toggleExpand}
      >
        <span className={styles.title}>{title}</span>
        <span>{isExpanded ? <CompressLines /> : <ExpandLines />}</span>
      </div>
      <div
        className={`${styles.contentWrapper} ${isExpanded ? styles.expanded : ''}`}
        style={{
          maxHeight: isExpanded ? `${contentHeight}px` : 0,
          overflow: 'hidden',
          transition: 'max-height 0.3s ease',
        }}
      >
        <div className={styles.content} ref={contentRef}>
          {children}
        </div>
      </div>
    </Card>
  );
};

export default ExpandableSection;
