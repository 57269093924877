import React from 'react';
import styles from './BarcodeResults.module.scss';
import { EmptyRecord, MissingBarcodeMessage, Modal, SkeletonLoader, WarningCard } from '../../../../components';
import ProductList from '../../../Catalogue/ProductList/ProductList';
import { Card } from '@orderly/morrisons-component-library';

interface IBarcodeResultsProps {
  isLoading: boolean;
  products: any[];
  maxOrderQuantity: number;
  missingBarcodeTitle: string;
  missingBarcodes: string[];
  productsLoaded: boolean;
  showPopup: boolean;
  onClosePopup: () => void;
}

const BarcodeResults: React.FC<IBarcodeResultsProps> = ({
  isLoading,
  products,
  maxOrderQuantity,
  missingBarcodeTitle,
  missingBarcodes,
  productsLoaded,
  showPopup,
  onClosePopup,
}) => (
  <div className={styles.container}>
    {showPopup && (
      <Modal
        header={missingBarcodeTitle}
        isOpen={showPopup}
        onClose={onClosePopup}
      >
        <MissingBarcodeMessage missingBarcodes={missingBarcodes} />
      </Modal>
    )}
    {
      missingBarcodes.length > 0 &&
      <WarningCard>
        <h4>{missingBarcodeTitle}</h4>
        <MissingBarcodeMessage missingBarcodes={missingBarcodes} />
      </WarningCard>
    }
    {
      isLoading &&
      <Card
        className={styles.loadingCard}
      >
        <SkeletonLoader />
      </Card>
    }
    {!isLoading && (
      productsLoaded ? (
        <ProductList
          products={products}
          maxOrderQuantity={maxOrderQuantity}
          scannerPage
        />
      ) : (
        <div className={styles.empty}>
          <EmptyRecord message="No products found." />
        </div>
      )
    )}
  </div>
);

export default BarcodeResults;
