import React, { useEffect, useRef, useState } from 'react';
import { FullPageLayout } from '../../../layouts';
import { ExpandableSection } from '../../../components';
import styles from './ScannerUserGuide.module.scss';

const SECTIONS = {
  TURN_ON_SCANNER: 'turn-on-scanner',
  CLEAR_SCANNER: 'clear-scanner',
  SCAN_PRODUCTS: 'scan-products',
  CONNECT_SCANNER: 'connect-scanner',
  UPLOAD_BARCODES: 'upload-barcodes',
  ADJUST_SETTINGS: 'adjust-settings',
  RESET_DEFAULT: 'reset-default',
} as const;

type Section = (typeof SECTIONS)[keyof typeof SECTIONS];

function ScannerUserGuide(): JSX.Element {
  const location = window.location;
  const [expandedSection, setExpandedSection] = useState<Section | null>(null);
  const sectionRefs = {
    [SECTIONS.TURN_ON_SCANNER]: useRef<HTMLDivElement>(null),
    [SECTIONS.CLEAR_SCANNER]: useRef<HTMLDivElement>(null),
    [SECTIONS.SCAN_PRODUCTS]: useRef<HTMLDivElement>(null),
    [SECTIONS.CONNECT_SCANNER]: useRef<HTMLDivElement>(null),
    [SECTIONS.UPLOAD_BARCODES]: useRef<HTMLDivElement>(null),
    [SECTIONS.ADJUST_SETTINGS]: useRef<HTMLDivElement>(null),
    [SECTIONS.RESET_DEFAULT]: useRef<HTMLDivElement>(null),
  };

  useEffect(
    () => {
      const timer = setTimeout(() => {
        const params = new URLSearchParams(location.search);
        const section = params.get('section');

        if (section && Object.values(SECTIONS).includes(section as Section)) {
          setExpandedSection(section as Section);
          const ref = sectionRefs[section as Section];
          if (ref && ref.current) {
            const element = ref.current;
            const header = document.querySelector('header');
            const headerHeight = header ? header.offsetHeight : 0;
            const rect = element.getBoundingClientRect();
            const scrollPosition = window.scrollY;
            const elementTop = rect.top + scrollPosition;
            const elementHeight = rect.height;
            const viewportHeight = window.innerHeight;
            const targetScroll = elementTop - (viewportHeight / 2) + (elementHeight / 2);

            window.scrollTo({
              top: targetScroll - headerHeight,
              behavior: 'smooth',
            });
          }
        }
      }, 500);

      return () => clearTimeout(timer);
    },
    [location.search],
  );

  return (
    <FullPageLayout
      heading="Scanner User Guide"
      breadcrumbs={[
        {
          key: 0,
          text: 'Scanner',
          url: '/scanner',
        },
        {
          key: 1,
          text: 'User Guide',
          url: window.location.href,
        },
      ]}
    >
      <div className={styles.container}>
        <i>Please follow the steps in order to complete device setup</i>
        <div ref={sectionRefs[SECTIONS.TURN_ON_SCANNER]} className="collapsible">
          <ExpandableSection
            title="1. Turn on scanner"
            isExpanded={expandedSection === SECTIONS.TURN_ON_SCANNER}
            toggleExpand={() => setExpandedSection(SECTIONS.TURN_ON_SCANNER)}
          >
            <div className={styles.content}>
              <ol>
                <li>Hold action button for 2 seconds to turn on the device</li>
                <li>A blue light should appear to indicate it is turned on</li>
              </ol>
              <div>
                <img
                  className={styles.image}
                  src="https://wholesale-cdn.orderly.io/scanner/scanner_power.png"
                  alt="Scanner Powered"
                />
              </div>
            </div>
          </ExpandableSection>
        </div>
        <div ref={sectionRefs[SECTIONS.CLEAR_SCANNER]} className="collapsible">
          <ExpandableSection
            title="2. Clear scanner"
            isExpanded={expandedSection === SECTIONS.CLEAR_SCANNER}
            toggleExpand={() => setExpandedSection(SECTIONS.CLEAR_SCANNER)}
          >
            <div className={styles.content}>
              <ol>
                <li>Scan the QR code using your scanner device</li>
                <li>This will erase any barcodes already stored in the device from previous gap scans</li>
              </ol>
              <div>
                <img
                  src="https://wholesale-cdn.orderly.io/scanner/clear_data.png"
                  alt="Clear Scanner Data QR Code"
                />
              </div>
            </div>
          </ExpandableSection>
        </div>
        <div ref={sectionRefs[SECTIONS.SCAN_PRODUCTS]} className="collapsible">
          <ExpandableSection
            title="3. Scan products in your store"
            isExpanded={expandedSection === SECTIONS.SCAN_PRODUCTS}
            toggleExpand={() => setExpandedSection(SECTIONS.SCAN_PRODUCTS)}
          >
            <div className={styles.content}>
              <div>
                <ol>
                  <li>Scan the barcode on either the SEL or the product</li>
                  <li>Scanning the same barcode multiple times will add multiple quantity to your order</li>
                </ol>
                <a href="/scanner/user-guide?section=adjust-settings" target="_self">Adjust Volume / Vibrate Settings</a>
              </div>
              <div>
                <img
                  src="https://wholesale-cdn.orderly.io/scanner/shelf_edge_label.png"
                  alt="Shelf Edge Label"
                />
              </div>
              <div>
                <img
                  className={styles.image}
                  src="https://wholesale-cdn.orderly.io/scanner/product_barcode.png"
                  alt="Product Barcode"
                />
              </div>
            </div>
          </ExpandableSection>
        </div>
        <div ref={sectionRefs[SECTIONS.CONNECT_SCANNER]} className="collapsible">
          <ExpandableSection
            title="4. Connect scanner to your computer"
            isExpanded={expandedSection === SECTIONS.CONNECT_SCANNER}
            toggleExpand={() => setExpandedSection(SECTIONS.CONNECT_SCANNER)}
          >
            <div className={styles.content}>
              <ol>
                <li>Connect the USB end into your computer</li>
                <li>Connect the micro USB connector into your scanner</li>
                <li>A blue light should appear on the device to indicate it is connected to your computer</li>
              </ol>
              <div>
                <img
                  className={styles.image}
                  src="https://wholesale-cdn.orderly.io/scanner/scanner_charging.png"
                  alt="Scanner Charging"
                />
              </div>
            </div>
          </ExpandableSection>
        </div>
        <div ref={sectionRefs[SECTIONS.UPLOAD_BARCODES]} className="collapsible">
          <ExpandableSection
            title="5. Upload barcodes / ordering"
            isExpanded={expandedSection === SECTIONS.UPLOAD_BARCODES}
            toggleExpand={() => setExpandedSection(SECTIONS.UPLOAD_BARCODES)}
          >
            <div className={styles.content}>
              <ol>
                <li>Scan the "Data Upload" QR code with the scanner</li>
                <li>Barcodes will start uploading</li>
                <li>A notification will appear once complete</li>
                <li>You can then continue to place an order</li>
              </ol>
              <div>
                <img
                  className={styles.image}
                  src="https://wholesale-cdn.orderly.io/scanner/mockup.png"
                  alt="Scanner Uploading Barcodes"
                />
              </div>
            </div>
          </ExpandableSection>
        </div>
        <div ref={sectionRefs[SECTIONS.ADJUST_SETTINGS]} className="collapsible">
          <ExpandableSection
            title="+ Adjust volume / Vibrate settings"
            isExpanded={expandedSection === SECTIONS.ADJUST_SETTINGS}
            toggleExpand={() => setExpandedSection(SECTIONS.ADJUST_SETTINGS)}
          >
            <div className={styles.content}>
              <i>Scan the QR codes below to adjust the scanner volume / vibrate settings</i>
              <div>
                <img
                  src="https://wholesale-cdn.orderly.io/scanner/volume_off.png"
                  alt="Volume off QR Code"
                />
                <img
                  src="https://wholesale-cdn.orderly.io/scanner/volume_low.png"
                  alt="Volume low QR Code"
                />
                <img
                  src="https://wholesale-cdn.orderly.io/scanner/volume_medium.png"
                  alt="Volume medium QR Code"
                />
                <img
                  src="https://wholesale-cdn.orderly.io/scanner/volume_high.png"
                  alt="Volume off high Code"
                />
              </div>
              <div>
                <img
                  src="https://wholesale-cdn.orderly.io/scanner/vibrate_off.png"
                  alt="Vibrate off QR Code"
                />
                <img
                  src="https://wholesale-cdn.orderly.io/scanner/vibrate_on.png"
                  alt="Vibrate on QR Code"
                />
              </div>
            </div>
          </ExpandableSection>
        </div>
        <div ref={sectionRefs[SECTIONS.RESET_DEFAULT]} className="collapsible">
          <ExpandableSection
            title="+ Reset to default settings"
            isExpanded={expandedSection === SECTIONS.RESET_DEFAULT}
            toggleExpand={() => setExpandedSection(SECTIONS.RESET_DEFAULT)}
          >
            <div className={styles.content}>
              <div>
                <p>
                  Scan the QR code below to reset the device to its default settings
                </p>
                <p>
                  Once scanned, please exit this window and complete the first time setup to restore default settings.
                </p>
              </div>
              <div>
                <img
                  src="https://wholesale-cdn.orderly.io/scanner/restore_default.png"
                  alt="Restore Default Settings QR Code"
                />
              </div>
            </div>
          </ExpandableSection>
        </div>
      </div>
    </FullPageLayout>
  );
}

export default ScannerUserGuide;
