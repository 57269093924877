import React, { useState, useEffect, useRef } from 'react';
import { Counter } from '@orderly/morrisons-component-library';
import { IQuantityCounterProps } from './IQuantityCounterProps';
import useDebounce from '../../helpers/Hooks/useDebounce';
import styles from './QuantityCounter.module.scss';

const QuantityCounter = ({
  itemId,
  baseQuantity,
  onQuantityChange,
  maxOrderQuantity,
}: IQuantityCounterProps) => {
  const [tempQuantity, setQuantity] = useState(baseQuantity);
  const debouncedQuantity = useDebounce(tempQuantity, 500);
  const firstRun = useRef(true);

  useEffect(
    () => {
      if (firstRun.current) {
        firstRun.current = false;
        return;
      }

      onQuantityChange(itemId, debouncedQuantity);
    },
    [debouncedQuantity],
  );

  return (
    <Counter
      key={`${itemId}`}
      className={`${styles.counter} quantity-counter`}
      changeBy={1}
      minValue={0}
      maxValue={maxOrderQuantity || 999}
      value={tempQuantity}
      onChange={q => setQuantity(q)}
    />
  );
};

export default QuantityCounter;
