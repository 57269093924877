import React, { useEffect, useRef, useState } from 'react';
import { FullPageLayout } from '../../../layouts';
import { ExpandableSection } from '../../../components';
import { navigateTo } from '../../../routing/Navigation';
import { Button } from '@orderly/morrisons-component-library';
import styles from './ScannerSetup.module.scss';

const SECTIONS = {
  UNBOXING: 'unboxing',
  CHARGING: 'charging',
  TURN_ON_SCANNER: 'turn-on-scanner',
  CONFIGURE: 'configure',
} as const;

type Section = (typeof SECTIONS)[keyof typeof SECTIONS];

function ScannerSetup(): JSX.Element {
  const location = window.location;
  const [expandedSection, setExpandedSection] = useState<Section | null>(null);
  const sectionRefs = {
    [SECTIONS.UNBOXING]: useRef<HTMLDivElement>(null),
    [SECTIONS.CHARGING]: useRef<HTMLDivElement>(null),
    [SECTIONS.TURN_ON_SCANNER]: useRef<HTMLDivElement>(null),
    [SECTIONS.CONFIGURE]: useRef<HTMLDivElement>(null),
  };

  useEffect(
    () => {
      const timer = setTimeout(() => {
        const params = new URLSearchParams(location.search);
        const section = params.get('section');

        if (section && Object.values(SECTIONS).includes(section as Section)) {
          setExpandedSection(section as Section);

          const ref = sectionRefs[section as Section];
          if (ref && ref.current) {
            const element = ref.current;
            const header = document.querySelector('header');
            const headerHeight = header ? header.offsetHeight : 0;
            const rect = element.getBoundingClientRect();
            const scrollPosition = window.scrollY;
            const elementTop = rect.top + scrollPosition;
            const elementHeight = rect.height;
            const viewportHeight = window.innerHeight;
            const targetScroll = elementTop - (viewportHeight / 2) + (elementHeight / 2);

            window.scrollTo({
              top: targetScroll - headerHeight,
              behavior: 'smooth',
            });
          }
        }
      }, 500);

      return () => clearTimeout(timer);
    },
    [location.search],
  );

  return (
    <FullPageLayout
      heading="Scanner Setup"
      breadcrumbs={[
        {
          key: 0,
          text: 'Scanner',
          url: '/scanner',
        },
        {
          key: 1,
          text: 'Setup',
          url: window.location.href,
        },
      ]}
    >
      <div className={styles.container}>
        <i>Please follow the steps in order to complete device setup</i>
        <div ref={sectionRefs[SECTIONS.UNBOXING]}>
          <ExpandableSection
            title="1. Unboxing"
            isExpanded={expandedSection === SECTIONS.UNBOXING}
            toggleExpand={() => setExpandedSection(SECTIONS.UNBOXING)}
          >
            <div className={styles.content}>
              <div>
                <p>You should recieve the following:</p>
                <ul>
                  <li>1x USB Reciever</li>
                  <li>1x Scanner Device</li>
                  <li>1x Micro USB cable</li>
                  <li>1x User Guide</li>
                </ul>
              </div>
              <div>
                <img
                  className={styles.image}
                  src="https://wholesale-cdn.orderly.io/scanner/scanner_unboxing.jpg"
                  alt="Scanner Unboxing"
                />
              </div>
            </div>
          </ExpandableSection>
        </div>
        <div ref={sectionRefs[SECTIONS.CHARGING]}>
          <ExpandableSection
            title="2. Charging scanner"
            isExpanded={expandedSection === SECTIONS.CHARGING}
            toggleExpand={() => setExpandedSection(SECTIONS.CHARGING)}
          >
            <div className={styles.content}>
              <div>
                <p>
                  Please complete the following steps when your device needs to be charged
                </p>
                <ol>
                  <li>Connect the USB end into your computer</li>
                  <li>Connect the micro USB connector into your scanner</li>
                  <li>You should see a light on the scanner to indicate it is charging</li>
                  <li>This will turn off when fully charged</li>
                </ol>
              </div>
              <div>
                <img
                  className={styles.image}
                  src="https://wholesale-cdn.orderly.io/scanner/scanner_charging.png"
                  alt="Scanner Charging"
                />
              </div>
            </div>
          </ExpandableSection>
        </div>
        <div ref={sectionRefs[SECTIONS.TURN_ON_SCANNER]}>
          <ExpandableSection
            title="3. Turn on scanner"
            isExpanded={expandedSection === SECTIONS.TURN_ON_SCANNER}
            toggleExpand={() => setExpandedSection(SECTIONS.TURN_ON_SCANNER)}
          >
            <div className={styles.content}>
              <div>
                <ol>
                  <li>Hold action button for 2 seconds to turn on the device</li>
                  <li>A light should appear to indicate it is turned on</li>
                </ol>
              </div>
              <div>
                <img
                  className={styles.image}
                  src="https://wholesale-cdn.orderly.io/scanner/scanner_power.png"
                  alt="Scanner Powered"
                />
              </div>
            </div>
          </ExpandableSection>
        </div>
        <div ref={sectionRefs[SECTIONS.CONFIGURE]}>
          <ExpandableSection
            title="4. Configure scanner"
            isExpanded={expandedSection === SECTIONS.CONFIGURE}
            toggleExpand={() => setExpandedSection(SECTIONS.CONFIGURE)}
          >
            <div className={styles.content}>
              <div>
                <ol>
                  <li>Please scan the QR code to configure the scanner</li>
                  <li>The scanner should make a "beep" sound</li>
                </ol>
              </div>
              <div>
                <img
                  src="https://wholesale-cdn.orderly.io/scanner/storage_mode.png"
                  alt="Storage Mode QR Code"
                />
              </div>
              <div>
                <img
                  className={styles.image}
                  src="https://wholesale-cdn.orderly.io/scanner/scanner_power.png"
                  alt="Scanner Powered"
                />
              </div>
            </div>
          </ExpandableSection>
        </div>
        <div className={styles.setupComplete}>
          <Button
            onClick={() => navigateTo('/scanner')}
            text="Setup Complete"
            className={'tertiary'}
            type="button"
          />
        </div>
      </div>
    </FullPageLayout>
  );
}

export default ScannerSetup;
