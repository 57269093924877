import 'react-app-polyfill/ie11';
import 'matchmedia-polyfill';
import 'matchmedia-polyfill/matchMedia.addListener';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store, persistor } from './store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './assets/style/style.scss';
import './index.scss';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import InactivityLogout from './InactivityLogout';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <InactivityLogout />
          <App />
        </BrowserRouter>
      </PersistGate>
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.register();
