import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getSearchResultsAdminRequest } from '../../actions/searchAction';
import { IBasketItem, IPendingOrder, IProduct, ISearchResults, IStoreState } from '../../types';
import { Dispatch } from 'redux';
import { Table } from 'react-bootstrap';
import { getPriceByType, PriceType, PriceSelectionType, ViewMode } from '../../helpers/Product/ProductPriceHelper';
import { TextInput, Button } from '@orderly/morrisons-component-library';
import InfiniteScroll from 'react-infinite-scroller';
import useDebounce from '../../helpers/Hooks/useDebounce';
import LoadingThrobber from '../LoadingThrobber/LoadingThrobber';
import QuantityCounter from '../QuantityCounter/QuantityCounter';

import './OrderReviewProductSearch.scss';
import ProductPromotionalPrice from '../ProductPromotionalPrice';

interface IOrderReviewProductSearchProps {
  order: IPendingOrder;
  results: ISearchResults;
  onAddItemToOrder: (item: IProduct) => void;
  onQuantityChange: (item: IBasketItem, quantity: number) => void;
  search: (searchPhrase: string, startIndex: number, pageSize: number, retailerId: number, categoryCode: string) => void;
}

function OrderReviewProductSearch({
  order,
  results,
  onAddItemToOrder,
  onQuantityChange,
  search,
}: IOrderReviewProductSearchProps): JSX.Element {
  const [pagingIndex, setPagingIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const deboundedSearchTerm = useDebounce(searchTerm, 500);

  const result = results[searchTerm.toLocaleLowerCase()];
  const data = result && result.data || undefined;
  const products = data && data.products || [];
  const PAGE_SIZE = 20;

  useEffect(
    () => {
      setPagingIndex(0);
      search(
        searchTerm,
        0,
        PAGE_SIZE,
        order.retailerId,
        order.combinedOrder ? null : order.categoryCode,
      );
    },
    [deboundedSearchTerm],
  );

  const loadSearchResults = () => {
    if (result && !result.loading) {
      setPagingIndex(pagingIndex + PAGE_SIZE);
      search(
        searchTerm,
        pagingIndex,
        PAGE_SIZE,
        order.retailerId,
        order.combinedOrder ? null : order.categoryCode,
      );
    }
  };

  return (
    <div className="admin-search-page-wrapper">
      <div className="search-box-wrapper">
        <TextInput
          label="Search"
          name="search"
          onChange={setSearchTerm}
          type="text"
          value={searchTerm}
        />
      </div>
      <div>
        <InfiniteScroll
          className="search-results-wrapper"
          pageStart={0}
          loadMore={loadSearchResults}
          initialLoad={false}
          hasMore={data && data.canLoadMore || false}
          loader={
            <div key="infiniteScrollSpinnerContainer" className="infinite-scroll-spinner-container">
              <LoadingThrobber preventScroll={false} />
            </div>
          }
        >
          <div className="search-results-list">
            <Table className="search-list">
              <thead>
                <tr className="table-header">
                  <th className="Table--stickyColumn">Product Name</th>
                  <th>Product Id</th>
                  <th>Case Size</th>
                  <th>Price</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {
                  products.length === 0 ?
                    <tr>
                      <td colSpan={4}>
                        {
                          !(result && result.loading) ?
                            (searchTerm.length === 0 ?
                              'Please enter a search term' :
                              `Sorry, we couldn't find any products to match for ${searchTerm}`
                            ) : <LoadingThrobber />
                        }
                      </td>
                    </tr> :
                    products.map((item: IProduct) => {
                      const itemIndex = order.items.findIndex(x => x.itemId === item.itemId);
                      const alreadyInOrder = itemIndex !== -1;
                      const existingItem = order.items[itemIndex];

                      return (
                        <tr key={item.itemId}>
                          <td>
                            {item.description}
                          </td>
                          <td>{item.id}</td>
                          <td>{item.caseSize}</td>
                          <td>
                            {
                              item.activeTradingPromotion ?
                                <ProductPromotionalPrice
                                  product={item}
                                  isListView={false}
                                  viewMode={ViewMode.Price}
                                /> :
                                getPriceByType(
                                  item,
                                  item.singlePick ? PriceType.UnitPrice : PriceType.CasePrice,
                                  PriceSelectionType.NormalPrice,
                                )
                            }
                          </td>
                          <td className="text-center">
                            {
                              !item.available || !alreadyInOrder
                                ? <Button
                                  type="button"
                                  className="tertiary"
                                  text="Add to Order"
                                  disabled={!item.available}
                                  onClick={() => onAddItemToOrder(item)}
                                /> : <QuantityCounter
                                  itemId={existingItem.itemId}
                                  baseQuantity={existingItem.quantity}
                                  onQuantityChange={(itemId, quantity) => onQuantityChange(existingItem, quantity)}
                                  maxOrderQuantity={999}
                                />
                            }
                          </td>
                        </tr>
                      );
                    })
                }
              </tbody>
            </Table>
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
}

const mapStateToProps = (state: IStoreState) => {
  return {
    results: state.searchResults,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    search: (
      searchPhrase: string,
      startIndex: number,
      pageSize: number,
      retailerId: number,
      categoryCode: string,
    ) => dispatch(
      getSearchResultsAdminRequest(searchPhrase, startIndex, pageSize, retailerId, categoryCode),
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderReviewProductSearch);
