import { ICountdown } from '../types';

export const isToday = (date: string) => {
  if (date === null) {
    return false;
  }

  const specifiedDate = new Date(date);
  const today = new Date();
  return specifiedDate.getDate() === today.getDate() &&
    specifiedDate.getMonth() === today.getMonth() &&
    specifiedDate.getFullYear() === today.getFullYear();
};

export const getRemainingTime = (difference: number): ICountdown => {
  const seconds = 1000;
  const minutes = seconds * 60;
  const hours = minutes * 60;
  const days = hours * 24;
  const weeks = days * 7;

  const timeWeeks = Math.floor(difference / weeks);
  const timeDays = Math.floor((difference % weeks) / days);
  const timeHours = Math.floor((difference % days) / hours);
  const timeMinutes = Math.floor((difference % hours) / minutes);
  const timeSeconds = Math.floor((difference % minutes) / seconds);

  return {
    weeks: timeWeeks,
    days: timeDays,
    hours: timeHours,
    minutes: timeMinutes,
    seconds: timeSeconds,
  };
};

export const formatCountdown = (remainingTime: ICountdown, length: number = 3): string[] => {
  const { weeks, days, hours, minutes, seconds } = remainingTime;
  const parts = [];

  if (weeks) {
    parts.push(`${weeks} week${weeks !== 1 ? 's' : ''}`);
  }
  if (days) {
    parts.push(`${days} day${days !== 1 ? 's' : ''}`);
  }
  if (hours) {
    parts.push(`${hours} hour${hours !== 1 ? 's' : ''}`);
  }
  if (minutes) {
    parts.push(`${minutes} minute${minutes !== 1 ? 's' : ''}`);
  }
  if (seconds || minutes || hours) {
    parts.push(`${seconds} second${seconds !== 1 ? 's' : ''}`);
  }

  return parts.slice(0, length);
};

export const adjustDeliveryDate = (isoDate: string): string => {
  if (!isoDate) {
    return isoDate;
  }

  const deliveryDate = new Date(isoDate);

  // Adjust for December 25th
  if (deliveryDate.getMonth() === 11 && deliveryDate.getDate() === 25) {
    deliveryDate.setDate(deliveryDate.getDate() + 2);
  }

  // Adjust for January 1st
  if (deliveryDate.getMonth() === 0 && deliveryDate.getDate() === 1) {
    deliveryDate.setDate(deliveryDate.getDate() + 1);
  }

  return deliveryDate.toISOString();
};
