import { IStatusMapping } from '../../components/OrderStatus/IStatusMapping';

const orderStatusTextMapping: IStatusMapping = {
  raised: 'Ordered',
  confirmed: 'Confirmed',
  picked: 'Confirmed',
  shipped: 'Shipped',
  accounted: 'Shipped',
  invoiced: 'Shipped',
  'confirmed-partial': 'Ordered',
  'picked-partial': 'Ordered',
  'shipped-partial': 'Confirmed',
  'accounted-partial': 'Shipped',
  'invoiced-partial': 'Shipped',
  Pending: 'Pending',
};

export const mapOrderStatus: (orderStatus: string) => string = (orderStatus: string): string => {
  return orderStatusTextMapping[orderStatus];
};
