import React, { Dispatch, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IStoreState } from '../../types';
import { getOrderByOrderNumberRequest } from '../../actions/Orders/orderLookupAction';
import { IGetOrderByOrderNumberParams } from '../../api/Orders/orderLookupApi';
import { getQueryString } from '../../helpers/UrlHelper';
import LoadingThrobber from '../../components/LoadingThrobber/LoadingThrobber';
import OrderOpportunities from '../../components/OrderOpportunities/OrderOpportunities';
import { Card } from '@orderly/morrisons-component-library';
import { mapCategory } from '../../helpers/Category/mapCategory';
import { formatUTCDateShort, formatUTCDateTimeShort } from '../../helpers/Format';
import { getTotalPrice } from '../../helpers/Product/ProductPriceHelper';
import FormatDateHelper from '../../helpers/Format/Date/FormatDateHelper';
import { IDateValues } from '../../helpers/Format/Date/IDateValues';
import { FullPageLayout } from '../../layouts';
import OrderError from '../../components/OrderError/OrderError';
import styles from './CopyOrder.module.scss';
import OrderItemsWithPriceChanges from '../../components/OrderItemsWithPriceChanges';
import OrderInfoCard from '../../components/OrderInfoCard';
import { ICopyOrderProps } from './ICopyOrderProps';
import retailerDetailsAction from '../../actions/retailerDetailAction';

function CopyOrder({
  retailerDetails,
  order,
  getOrderByOrderNumber,
  getRetailerDetails,
}: ICopyOrderProps): JSX.Element {
  const [retailerStore, setRetailerStore] = useState(undefined);
  const orderNumber = getQueryString('orderNumber');

  useEffect(
    (): void => {
      getRetailerDetails();
    },
    [],
  );

  useEffect(
    () => {
      getOrderByOrderNumber({ orderNumber });
    },
    [orderNumber],
  );

  useEffect(
    () => {
      if (order && order.data && retailerDetails && retailerDetails.data && retailerDetails.data.stores) {
        const store = retailerDetails.data.stores.find(x => order.data.storeId === x.externalId);
        setRetailerStore(store);
      }
    },
    [retailerDetails, order],
  );

  if (order.loading || retailerDetails.loading) {
    return (
      <div>
        <LoadingThrobber />
      </div>
    );
  }

  const date = new Date(order && order.data && order.data.deliveryDate);
  const dateValues: IDateValues = FormatDateHelper.getDateValues(date.toISOString());

  return (
    <FullPageLayout
      heading="Copy Order"
      breadcrumbs={[
        {
          key: 0,
          text: 'Orders',
          url: '/orders',
        },
        {
          key: 1,
          text: 'Copy Order',
          url: `/orders/copy?orderNumber=${orderNumber}`,
        },
      ]}
    >
      {
        !order.data ?
          <OrderError />
          :
          <div className={styles.container}>
            <div className={styles.column}>
              <OrderInfoCard
                orderDay={dateValues.date}
                orderMonth={dateValues.month}
                orderNumber={order.data.orderNumber}
              >
                <table className={styles.orderDetails}>
                  <tbody>
                    <tr>
                      <td>
                        PO Number
                      </td>
                      <td>
                        <strong>{order.data.poNumber ? order.data.poNumber : 'N/A'}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Delivery Date
                      </td>
                      <td>
                        {formatUTCDateShort(new Date(order.data.deliveryDate))}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Order Cut-Off
                      </td>
                      <td>
                        {formatUTCDateTimeShort(new Date(order.data.lockAt))}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Category
                      </td>
                      <td>
                        {mapCategory(order.data.categoryCode)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Store
                      </td>
                      <td>
                        {retailerStore && retailerStore.addressName || 'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Item Count
                      </td>
                      <td>
                        {order.data.items.length}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Total Value
                      </td>
                      <td>
                        {getTotalPrice(order.data.items)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </OrderInfoCard>
              {
                order.data.items.length > 0 &&
                <Card className={styles.leftColumn}>
                  <h4>
                    Order Items
                  </h4>
                  <table>
                    <thead>
                      <tr><th>Description</th></tr>
                    </thead>
                    <tbody>
                      {
                        order.data.items.map(
                          item => <tr key={item.id}>
                            <td>
                              {item.description}
                            </td>
                          </tr>,
                        )
                      }
                    </tbody>
                  </table>
                </Card>
              }
            </div>
            <div className={styles.column}>
              <OrderItemsWithPriceChanges orderNumber={orderNumber} />
              <Card className={styles.opportunities}>
                <div className={styles.opportunitiesHeadingWrapper}>
                  <h4>
                    Please select a slot to copy this order to:
                  </h4>
                </div>
                <OrderOpportunities
                  copyingOrder={{
                    orderNumber,
                    categoryCode: order.data.categoryCode,
                  }}
                />
              </Card>
            </div>
          </div>
      }
    </FullPageLayout>
  );
}

const mapStateToProps = (state: IStoreState) => {
  return {
    order: state.orderLookup,
    retailerDetails: state.retailerDetails,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getRetailerDetails: () => dispatch(retailerDetailsAction()),
    getOrderByOrderNumber: (params: IGetOrderByOrderNumberParams) => dispatch(getOrderByOrderNumberRequest(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CopyOrder);
