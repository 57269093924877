import React, { Dispatch, useMemo } from 'react';
import { Button } from '@orderly/morrisons-component-library';
import { IStoreState } from '../../types';
import { updateBasketRequest } from '../../actions/Basket/updateBasketAction';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import {
  viewCategorySlots,
  addToBasketText,
  requireNewSlot,
  addToBasketActive,
  allowAddToBasket,
} from '../../helpers/Basket';
import QuantityCounter from '../QuantityCounter/QuantityCounter';
import { IBasketOptionsProps } from './IBasketOptionsProps';

const BasketOptions = ({
  itemId,
  basket,
  addToBasket,
  category,
  status,
  available,
  showLabels,
  maxOrderQuantity,
}: IBasketOptionsProps): JSX.Element => {
  const item = useMemo(
    () => {
      if (basket.data && basket.data.items && basket.data.items.length > 0) {
        return basket.data.items.find(product => product.itemId === itemId) || null;
      }
      return null;
    },
    [basket, itemId],
  );

  const handleButtonOnClick = (item: number, quantity: number) => {
    if (requireNewSlot(basket)) {
      viewCategorySlots(category);
    } else if (!basket.loading) {
      toastr.success(
        '',
        quantity === 1 ? 'Item successfully added to the basket.' : 'Item successfully updated in your basket.',
      );
      addToBasket(item, quantity);
    }
  };

  const onQuantityChange = (itemId: number, quantity: number) => {
    handleButtonOnClick(itemId, quantity);
  };

  if (requireNewSlot(basket) || !allowAddToBasket(basket, category, status, available) || item === null) {
    return (
      <Button
        type="button"
        className={'add-to-basket tertiary'}
        text={addToBasketText(basket, category, status, itemId, available)}
        disabled={addToBasketActive(basket, category, status, available)}
        onClick={() => handleButtonOnClick(itemId, item === null ? 1 : 0)}
      />
    );
  }

  return (
    <React.Fragment>
      {showLabels ? (
        <span>
          <div className="quantity">
            <span>Quantity</span>
            <QuantityCounter
              itemId={item.itemId}
              baseQuantity={item.quantity}
              onQuantityChange={(itemId, quantity) => onQuantityChange(itemId, quantity)}
              maxOrderQuantity={maxOrderQuantity}
            />
          </div>
        </span>
      ) : (
        <QuantityCounter
          itemId={item.itemId}
          baseQuantity={item.quantity}
          onQuantityChange={(itemId, quantity) => onQuantityChange(itemId, quantity)}
          maxOrderQuantity={maxOrderQuantity}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    basket: state.basket,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    addToBasket: (itemId: number, quantity: number) => dispatch(updateBasketRequest(itemId, quantity)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BasketOptions);
