import React from 'react';
import { FullPageLayout } from '../../../layouts';
import styles from './ScannerLanding.module.scss';
import { Button, Card } from '@orderly/morrisons-component-library';
import { navigateTo } from '../../../routing/Navigation';

function ScannerLanding(): JSX.Element {
  return (
    <FullPageLayout
      heading="Scanner"
      breadcrumbs={[
        {
          key: 0,
          text: 'Scanner',
          url: '/scanner',
        },
      ]}
    >
      <div className={styles.container}>
        <Card className={styles.card}>
          <h4>Please select one of the following options:</h4>
          <div className={styles.grid}>
            <div className={styles.option}>
              <span>If you have a new scanner and are yet to set it up</span>
            </div>
            <div>
              <Button
                onClick={() => navigateTo('/scanner/setup')}
                text="Setup Scanner"
                className="tertiary"
                type="button"
              />
            </div>

            <div className={styles.option}>
              <span>Select to open the user guide</span>
            </div>
            <div>
              <Button
                onClick={() => navigateTo('/scanner/user-guide')}
                text="User Guide"
                className="tertiary"
                type="button"
              />
            </div>

            <div className={styles.option}>
              <span>Alternatively, if you have already set up your scanner</span>
            </div>
            <div>
              <Button
                onClick={() => navigateTo('/scanner/place-order')}
                text="Place Order"
                className="tertiary"
                type="button"
              />
            </div>
          </div>
        </Card>
      </div>
    </FullPageLayout>
  );
}

export default ScannerLanding;
